import React from 'react'
import logo_user from '../../assests/images/logo_user.png'
import welcomeImg from "../../assests/images/welcomeImg.svg";
import paymentSuccess from '../../assests/images/grenTick.png'
import { useNavigate } from 'react-router';
function PaymentSuccess() {
const navigate=useNavigate()
    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <section className="loginSection">
                <div className="container centerDiv">
                    <div className="loginBgImg"></div>
                    <div className="loginBg">
                        <div className="row">
                            <div className="col-md-12 logincredDetail">
                                <div className="loginFormSec login_left_section">
                                    <div className="loginLogo">
                                        <img src={paymentSuccess} alt="" className='w-25' />
                                        <h1>Payment Successful!</h1>
                                    </div>
                                    <p>Your payment has been processed successfully. Thank you for your purchase.</p>
                                    <center> <button
                                        type="button"
                                        className="btn btn-labeled btn-primary"
                                        onClick={() => navigate('/dashboard')}
                                    >
                                     <i class="fa-solid fa-arrow-left"></i>  Back to dashboard
                                    </button></center>
                                    <div className="loginFormSec"></div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>

    );


}

export default PaymentSuccess