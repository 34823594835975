import React from 'react'
import SubmitButton from '../Common/buttons/SubmitButton'
import Select from "react-select";
import { useState } from 'react';
function TopbarSection({ t, rankList, handleSelecthanler, selectBoxvalue }) {

    return (
        <div className="filter_Section mb-2">
            
            <div className="row">
                <div key={"1"} className="col-lg-6">
                    <div className="right_search_div">
                        <label className="la-control">{t("rank")}</label>
                        <Select
                            options={rankList?.ranks}
                            onChange={handleSelecthanler}
                            value={selectBoxvalue}

                        />
                    </div>
                </div>

                
            </div>
        </div>
    )
}

export default TopbarSection