import React, { useState } from "react";
import { Offcanvas, Form } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { ApiHook } from "../../hooks/apiHook";
import { useQueryClient } from "@tanstack/react-query";
import SubmitButton from "../Common/buttons/SubmitButton";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { epinCurrencyConverter } from "../../utils/epinCurrencyConversion";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Stripe from "../../views/payments/Stripe";
const EpinPurchase = ({
  show,
  handleClose,
  amounts,
  balance,
  currency,
  conversionFactor,
}) => {
  const { t } = useTranslation();
  const errorClassName = "border-color: var(--bs-form-invalid-border-color);";
  const [formState, setFormState] = useState({
    // amountCount: [],
    amountCount: 0,
    epinCount: "",
    expiryDate: "9999-01-01",
    transactionPassword: "",
  });
  console.log("formstate =========== ", formState);
  const [clientSecret, setclientSecret] = useState("")
  const [totalAmount, setTotalAmount] = useState(0)
  const [errorMessage, setErrorMessage] = useState({
    amountCount: null,
    epinCount: null,
    expiryDate: null,
    transactionPassword: null,
  });

  const [purchasedEpins, setpurchasedEpins] = useState({
    amountCode: [],
    epinCount: '',
    expiryDate: "",
    transactionPassword: "",
  })
  const queryClient = useQueryClient();
  console.log("=====expiryDate", formState.expiryDate)
  // Api call for Epin Purchase
  const purchaseMutation = ApiHook.CallEpinPurchase();
  const paymentIntent = ApiHook.CallEpinPaymentIntent();
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrorMessage((prevData) => ({
      ...prevData,
      [id]: null,
    }));
    if (value === null || value === "") {
      setErrorMessage((prev) => ({
        ...prev,
        [id]: "*Required",
      }));
    }
  };

  const handleAmountChange = (amountCount) => {
    if (amountCount?.length === 0) {
      setErrorMessage((prev) => ({
        ...prev,
        amountCount: "*Required",
      }));
    } else {
      setErrorMessage((prevData) => ({
        ...prevData,
        amountCount: null,
      }));
    }

    setFormState((prevData) => ({
      ...prevData,
      amountCount,
    }));
  };

  const isFormValid = () => {
    return (
      // formState?.amountCount?.length > 0 &&
      formState?.amountCount> 0 &&
      formState?.epinCount > 0 &&
      formState?.expiryDate.trim() !== "" &&
      formState?.transactionPassword.trim() !== ""
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isFormValid()) {
      return;
    }
    const currentDate = new Date();
    currentDate.setHours(23, 59, 59, 999); // Set time to midnight

    const expiryDate = new Date(formState.expiryDate);
    if (expiryDate <= currentDate) {
      // Display an error message if the expiryDate is not greater than today
      setErrorMessage((prevErrors) => ({
        ...prevErrors,
        expiryDate: t("expiry_date_must_be_greater_than_today"),
      }));
      return;
    }
    // const amountValues = formState.amountCount?.map((option) => option.value);
    const amountValues = formState.amountCount;
    const data = {
      amountCode: parseInt(amountValues),
      epinCount: formState.epinCount,
      expiryDate: formState.expiryDate,
      transactionPassword: formState.transactionPassword,
    };
    // paymentIntent.mutateAsync(data, {
    //   onSuccess: (res) => {
    //     console.log("======response=====", res?.data?.client_secret)
    //     if (res.status === 200) {
    //       setclientSecret(res?.data?.client_secret ?? '')
    //       setTotalAmount(res?.data?.totalAmount)
    //       setpurchasedEpins({
    //         ...purchasedEpins,
    //         amountCode: res?.data?.amountCode,
    //         epinCount: res?.data?.epinCount,
    //         expiryDate: res?.data?.expiryDate,
    //         transactionPassword: res?.data?.transactionPassword
    //       })
    //       setErrorMessage({
    //         amountCount: null,
    //         transactionPassword: null,
    //       });
    //       setFormState({
    //         amountCount: [],
    //         epinCount: "",
    //         expiryDate: "",
    //         transactionPassword: "",
    //       });
    //       // queryClient.invalidateQueries({ queryKey: ["epin-tiles"] });
    //       // queryClient.invalidateQueries({ queryKey: ["epin-lists"] });
    //       // queryClient.invalidateQueries({ queryKey: ['purchased-epin-list'] })
    //       // handleClose(false);
    //     } else {
    //       if (res?.data?.code === 1014) {
    //         setErrorMessage((prevErrors) => ({
    //           ...prevErrors,
    //           amountCount: t(res?.data?.description),
    //         }));
    //       } else if (res?.data?.code === 1015) {
    //         setErrorMessage((prevErrors) => ({
    //           ...prevErrors,
    //           transactionPassword: t(res?.data?.description),
    //         }));
    //       } else if (res?.data?.code === 429) {
    //         setErrorMessage((prevErrors) => ({
    //           ...prevErrors,
    //           amountCount: t(res?.data?.description),
    //         }));
    //       } else if (res?.data?.code) {
    //         toast.error(res?.data?.description);
    //       } else {
    //         toast.error(res?.data?.message);
    //       }
    //     }
    //   },
    // })
    purchaseMutation.mutateAsync(data, {
      onSuccess: (res) => {
        console.log("======response=====", res?.data)
        if (res.status === 200) {
          setclientSecret(res?.data?.data?.client_secret ?? '')
          setTotalAmount(res?.data?.data?.totalAmount)
          setErrorMessage({
            amountCount: null,
            transactionPassword: null,
          });
          setFormState({
            amountCount: [],
            epinCount: "",
            expiryDate: "9999-01-01",
            transactionPassword: "",
          });
          // queryClient.invalidateQueries({ queryKey: ["epin-tiles"] });
          // queryClient.invalidateQueries({ queryKey: ["epin-lists"] });
          // queryClient.invalidateQueries({ queryKey: ['purchased-epin-list'] })
          // handleClose(false);
        } else {
          if (res?.data?.code === 1014) {
            setErrorMessage((prevErrors) => ({
              ...prevErrors,
              amountCount: t(res?.data?.description),
            }));
          } else if (res?.data?.code === 1015) {
            setErrorMessage((prevErrors) => ({
              ...prevErrors,
              transactionPassword: t(res?.data?.description),
            }));
          } else if (res?.data?.code === 429) {
            setErrorMessage((prevErrors) => ({
              ...prevErrors,
              amountCount: t(res?.data?.description),
            }));
          } else if (res?.data?.code) {
            toast.error(res?.data?.description);
          } else {
            toast.error(res?.data?.message);
          }
        }
      },
    });
  };
  // console.log("====clientSecret===", clientSecret)
  return (
    <Offcanvas show={show} onHide={handleClose} placement="end" >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{t("ePinPurchase")}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {clientSecret &&
          clientSecret ?
          <Stripe
            clientSecret={clientSecret}
            totalAmount={totalAmount}
            data={purchasedEpins}
            handleClose={handleClose}
            action="Epin_purchase"
            setclientSecret={setclientSecret} />
          :
          <Form>
            {/* <Form.Group className="mb-3">
              <Form.Label>{t("currentBalance")}</Form.Label>
              <div className="input-group">
                <Form.Control as="select" className="max-40">
                  <option value="">{currency?.symbolLeft}</option>
                </Form.Control>
                <Form.Control
                  id="balance"
                  type="text"
                  placeholder="Current Balance"
                  value={CurrencyConverter(balance, conversionFactor)}
                  disabled
                />
              </div>
            </Form.Group> */}

            {/* <Form.Group className="mb-3">
              <Form.Label>{t("amount")}</Form.Label>
              <MultiSelect
                id="amountCount"
                options={epinCurrencyConverter(amounts, conversionFactor) ?? ""}
                value={formState.amountCount}
                onChange={handleAmountChange}
                labelledBy="Select"
                className={
                  errorMessage?.amountCount !== null ? errorClassName : ""
                }
              />
              <div className="required_dropDown">
                {errorMessage?.amountCount ? errorMessage?.amountCount : ""}
              </div>
            </Form.Group> */}

            <Form.Group className="mb-3">
              <Form.Label>{t("amount")}</Form.Label>
              <Form.Control
                type="number"
                id="amountCount"
                value={formState.amountCount}
                placeholder="Enter amount"
                required
                onChange={(e) => handleChange(e)}
                isInvalid={errorMessage?.amountCount !== null}
                min={0}
              />
              <Form.Control.Feedback type="invalid">
                {errorMessage?.amountCount}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{t("epinCount")}</Form.Label>
              <Form.Control
                type="number"
                id="epinCount"
                value={formState.epinCount}
                placeholder="E-Pin Count"
                required
                onChange={(e) => handleChange(e)}
                isInvalid={errorMessage?.epinCount !== null}
              />
              <Form.Control.Feedback type="invalid">
                {errorMessage?.epinCount}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" style={{ display: 'none' }}>
              <Form.Label>{t("expiryDate")}</Form.Label>
              <Form.Control
                id="expiryDate"
                type="date"
                value={formState.expiryDate}
                placeholder="Expiry Date"
                onChange={(e) => handleChange(e)}
                isInvalid={errorMessage?.expiryDate !== null}
              />
              <Form.Control.Feedback type="invalid">
                {errorMessage?.expiryDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{t("transactionPassword")}</Form.Label>
              <Form.Control
                id="transactionPassword"
                type="password"
                value={formState.transactionPassword}
                placeholder="Transaction Password"
                onChange={(e) => handleChange(e)}
                required
                isInvalid={errorMessage?.transactionPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errorMessage.transactionPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <SubmitButton
              isSubmitting={!isFormValid() || purchaseMutation.isLoading}
              click={handleSubmit}
              text={purchaseMutation.isLoading ? `${t("processing")}...` : t("next")}
              className={"btn btn-primary submit_btn"}
            />
          </Form>
        }

      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default EpinPurchase;
