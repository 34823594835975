import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../components/Common/buttons/SubmitButton";
import { ApiHook } from "../../hooks/apiHook";
import moment from "moment";
import _ from "lodash";
import { formatDateWithoutTime } from "../../utils/formateDate";

export default function CycleReport() {
  const { t } = useTranslation();
  const report = ApiHook.CallWeeklyReport();
  const headers = [
    t("cycle"),
    t("startDate"),
    t("endDate"),
    t("week1"),
    t("week2"),
    t("week3"),
    t("week4"),
  ];
  const [year, setYear] = useState(moment().year());
  const [tableData, setTableData] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);

  const changeHandler = (e) => {
    const { value } = e.target;
    setYear(value);
  };

  const resetHandler = () => {
    setYear(moment().year());
  };

  const submitHandler = async () => {
    const response = await report.mutateAsync({ year: year });
    setTableData(response.data.data.tableData);
    const years = _.range(
      response.data.data.startingYear,
      Number(moment().year()) + 1
    );
    setYearOptions(years);
  };

  useEffect(() => {
    submitHandler();
  }, [year]);

  return (
    <>
      <div className="page_head_top">{t("weekly-report")}</div>
      <div className="ewallet_table_section">
        <div className="ewallet_table_section_cnt"></div>{" "}
        <div className="filter_Section">
          <div className="row justify-content-between">
            <div className="col-md-4">
              <div className="right_search_div d-flex gap-1">
                <select
                  className="form-control"
                  name="year"
                  id="year"
                  value={year}
                  onChange={changeHandler}
                >
                  {yearOptions.map((year) => (
                    <option value={year}>{year}</option>
                  ))}
                </select>
                <SubmitButton
                  isSubmitting=""
                  text="Reset"
                  className="btn btn-secondary"
                  click={resetHandler}
                  id="resetButton"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive min-hieght-table">
          <div className="table-container">
            <table className="striped" style={{ width: "100%" }}>
              <thead>
                <tr>
                  {headers.map((item, index) => (
                    <th key={index}>{item}</th>
                  ))}
                </tr>
              </thead>
              {tableData.length <= 0 ? (
                <tbody>
                  <tr>
                    <td colSpan="6">
                      <div className="nodata-table-view">
                        <div className="nodata-table-view-box">
                          <div className="nodata-table-view-box-img">
                            <img src="/images/no-data-image1.jpg" alt="" />
                          </div>
                          <div className="nodata-table-view-box-txt">
                            {t("sorry_no_data_found")}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {tableData?.map((row, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td> {formatDateWithoutTime(row.startDate)}</td>
                      <td>{formatDateWithoutTime(row.endDate)}</td>
                      <td>{row?.week1 ?? 0}</td>
                      <td>{row?.week2 ?? 0}</td>
                      <td>{row?.week3 ?? 0}</td>
                      <td>{row?.week4 ?? 0}</td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
