import API from "../../api/api";

const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};


export const reportServices={
    Callrankreport:async(rank,searchParams)=>{
        const response = await callApi(`get-user-rank-commission?rank=${rank}&searchParam=${searchParams}`)
        return response
    },
    CallgetRanks:async()=>{
        const response=await callApi('get-user-ranks')
        return response
    },
    CallweeklyReport:async(date)=>{
        const response = await callApi(`user-weekly-report?date=${date}`)
        return response
    }
}   