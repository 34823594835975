import React from 'react'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from 'react';


function TravelAwards({ data }) {
    console.log("==TravelAwards====",data)
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(data[0]?.awardId ?? "");
    const moduleStatus = useSelector(
        (state) => state.dashboard?.appLayout?.moduleStatus
    );
    const [list, setList] = useState(data[0] ?? "");
    //   const expenses = ApiHook.CallDahboardExpenses(expenseCheck, setExpenseCheck);
    console.log("list 7777 ", list, activeTab);
    const handleTabClick = (list) => {
        console.log("==list====", list)
        setActiveTab(list.awardId);
        setList(list);
    };
    console.log("===list====", list)
    const formatNumber = (number) => {
        if (number >= 1000000) {
            return `${number / 1000000}m`;
        } else if (number >= 1000) {
            return `${number / 1000}k`;
        } else {
            return number;
        }
    };
   
    return (
        <div className={moduleStatus?.rank_status ? "col-md-4" : "col-md-5"}>
            <div className="ranking-box">
                <div className="joinings_viewBox_head">
                    <h5>{t("travelAward")}</h5>
                </div>

                {
                    <ul
                        className="teamPerfomance_tab nav nav-tabs mb-3"
                        id="ex1"
                        role="tablist"
                    >
                        {data &&
                            data.map((item) => {
                                return (
                                    <>
                                        <li className="nav-item" role="presentation">
                                            <Link
                                                className={`nav-link${activeTab === item.awardId ? " active" : ""
                                                    }`}
                                                id={`ex1-tab-${item.awardId}`}
                                                data-bs-toggle="tab"
                                                role="tab"
                                                //   aria-controls={"earnings"}
                                                aria-selected={activeTab === item.awardId}
                                                onClick={() => handleTabClick(item)}
                                            >
                                                {t(item.awardName)}
                                            </Link>
                                        </li>
                                    </>
                                );
                            })}

                    </ul>
                }

                <div className="tab-content" id="ex2-content">

                    {data.length === 0 && (
                        <div className="no-data-div">
                            <div className="no-data-div-image">
                                <img src="/images/nodata-image.png" alt="" />
                            </div>
                            <p>{t("noDataFound")}</p>
                        </div>
                    )}
                    {list && data.length > 0 && (
                        <>
                            <div>
                                <div className="car_reward_title">{t("criteria")}</div>
                                <div className='rightendHeading'>
                                    <span>{t('achieved')}</span>&nbsp;&nbsp;
                                    <span>{t('required')}</span>
                                </div>
                                {Object.entries(list?.criteria).map(
                                    ([key, value]) => {

                                        return (
                                            <>
                                                <div className="teammbrs_cnt_row">
                                                    <div className="teammbrs_cnt_name_dtl">

                                                        <div className="earning_expence_row">{t(key)}</div>
                                                    </div>
                                                    <div style={{ color: "rgb(3, 173, 71)" }}>
                                                        <div className="teammbrs_cnt_name_dtl">
                                                            {
                                                                value.status ?
                                                                    <div className="top_recuirters_num_travel">

                                                                        <div style={{ display: 'inline-flex', gap: '2%' }}>
                                                                            <span className='achieved-span' style={{ background: '#87df87' }}>
                                                                                <i class="fa-solid fa-check"></i>
                                                                            </span>
                                                                            <span style={{opacity:0}}></span>

                                                                        </div>

                                                                    </div>
                                                                    :
                                                                    <div className="top_recuirters_num_travel">
                                                                        <div style={{ display: 'inline-flex', gap: '2%' }}>
                                                                            <span className='achieved-span' style={{ background: '#7cbfe8' }}>{formatNumber(Number(value.achieved))}</span>
                                                                            <span>{formatNumber(Number(value.required))}</span>
                                                                        </div>

                                                                    </div>

                                                            }

                                                        </div>


                                                    </div>
                                                </div>
                                            </>
                                        );
                                    }
                                )}
                            </div>

                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TravelAwards