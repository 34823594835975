// const BASE_URL = "http://192.168.21.2:5001/node_api";
// const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const PAYPAL_CLIENT_ID = ""
// const STRIPE_PK = "pk_test_51OPlMBHNF2myN32T4ULWs3Q416p4Nn9Btkq2qIQWuknhkr7C2WxxwkDdddL78hh2j7lcMYeOWCbzJkQ974D2sYmx003HjL2aIE"


// //demo
// const BASE_URL = "https://njoya.admin.iossmlm.com/node_api";
// const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const PAYPAL_CLIENT_ID = ""
// const STRIPE_PK = "pk_test_51LHkzeKD7ZhA819wxNlZjA7rJtL4QnhqxPdo0BX5QF7L2QxN9Z8X0eQi3YTuWMs5izAI02xEk88TPaMx1bHUMVqc00CDofyNSN"



//live
const BASE_URL = "https://admin.webveddal.com/node_api";
const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
const PAYPAL_CLIENT_ID = ""
const STRIPE_PK = "pk_live_51IiEsOJjPQw79wWTyWfDpGWmBf6j5OroJTqnYxc51Pmm1qKwxad0pjSNc75KQSe3VS14uhTIVeuYhK1nmV2TCX8U00fg5AFKqw"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID,STRIPE_PK }