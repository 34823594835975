import React, { useState } from "react";
import { Offcanvas, Form } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { ApiHook } from "../../hooks/apiHook";
import { useQueryClient } from "@tanstack/react-query";
import SubmitButton from "../Common/buttons/SubmitButton";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { epinCurrencyConverter } from "../../utils/epinCurrencyConversion";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Stripe from "../../views/payments/Stripe";
import Select from 'react-select';
function EpinRecharge({
    show,
    handleClose,
    amounts,
    balance,
    currency,
    purchasedEpin,
    conversionFactor,
    paymentMethodss
}) {
    console.log("====purchasedEpin====", purchasedEpin)
    const rechargeMutation = ApiHook.CallEpinRecharge();
    const purchasedEpins = ApiHook.CallPurchasedEpinList()
    const { t } = useTranslation();
    const errorClassName = "border-color: var(--bs-form-invalid-border-color);";
    const [formState, setFormState] = useState({
        amount: null,
        epin: null,
        expiryDate: "9999-01-01",
        paymentMethod: null
    });

    const [clientSecret, setclientSecret] = useState("")
    const [totalAmount, setTotalAmount] = useState(0)
    const [errorMessage, setErrorMessage] = useState({
        amount: null,
        epin: null,
        expiryDate: null,
        paymentMethod: null

    });


    const queryClient = useQueryClient();

    // Api call for Epin Purchase
    const purchaseMutation = ApiHook.CallEpinPurchase();

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormState((prevData) => ({
            ...prevData,
            [id]: value,
        }));
        setErrorMessage((prevData) => ({
            ...prevData,
            [id]: null,
        }));
        if (value === null || value === "") {
            setErrorMessage((prev) => ({
                ...prev,
                [id]: "*Required",
            }));
        }
    };
    console.log("===formState===", formState)
    const handleSelectChange = (type, value) => {
        const arrFromObj = Object.keys(value);
        console.log("====================amount", arrFromObj)
        if (arrFromObj.length === 0) {
            setErrorMessage((prev) => ({
                ...prev,
                [type]: "*Required",
            }));
        }
        else {
            setErrorMessage((prevData) => ({
                ...prevData,
                [type]: null,
            }));
        }

        setFormState((prevData) => ({
            ...prevData,
            [type]: value
        }));
    };

    const isFormValid = () => {
        return (
            formState?.amount &&
            formState.epin &&
            formState.paymentMethod
            // formState?.expiryDate.trim() !== ""
        );
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isFormValid()) {
            return;
        }
        const currentDate = new Date();
        currentDate.setHours(23, 59, 59, 999); // Set time to midnight

        const expiryDate = new Date(formState.expiryDate);
        if (expiryDate <= currentDate) {
            // Display an error message if the expiryDate is not greater than today
            setErrorMessage((prevErrors) => ({
                ...prevErrors,
                expiryDate: t("expiry_date_must_be_greater_than_today"),
            }));
            return;
        }

        let data = {
            // amount: formState.amount.value,
            amount: parseInt(formState.amount),
            epin: formState.epin.value,
            expiryDate: formState.expiryDate??"9999-01-01",
            paymentMethod: formState.paymentMethod.value
        }
        await rechargeMutation.mutateAsync(data, {
            onSuccess: (res) => {
                console.log("response=================", res.data.data)
                if (res.status === 200) {

                    setclientSecret(res?.data?.data?.client_secret ?? '')
                    setTotalAmount(res?.data?.data?.totalAmount)
                    setErrorMessage({
                        amount: null,
                        epin: null,
                        expiryDate: null
                    });
                    setFormState({
                        amount: null,
                        epin: null
                    });
                    queryClient.invalidateQueries({ queryKey: ["recharge-epins"] })
                    if (res?.data?.data?.method === "ewallet") {
                        handleClose(false);
                        queryClient.invalidateQueries({ queryKey: ["epin-tiles"] });
                        queryClient.invalidateQueries({ queryKey: ["epin-lists"] });
                        queryClient.invalidateQueries({ queryKey: ['purchased-epin-list'] })
                    }


                } else {
                    if (res?.data?.code === 1014) {
                        setErrorMessage((prevErrors) => ({
                            ...prevErrors,
                            amount: t(res?.data?.description),
                        }));
                    } else if (res?.data?.code === 429) {
                        setErrorMessage((prevErrors) => ({
                            ...prevErrors,
                            amount: t(res?.data?.description),
                        }));
                    } else if (res?.data?.code) {
                        toast.error(res?.data?.description);
                    } else {
                        toast.error(res?.data?.message);
                    }
                }
            },
        });
    };

    return (
        <Offcanvas show={show} onHide={handleClose} placement="end" >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("epinRecharge")}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {purchasedEpin &&
                    purchasedEpin.length === 0 ?
                    <>
                        <center> <img src="/images/nodata-image.png" alt="" className="w-50" />

                            <h5 style={{ opacity: '0.5' }}>Please purchase Epins.</h5></center>
                    </>

                    :
                    <>
                        {clientSecret &&
                            clientSecret ?
                            <Stripe clientSecret={clientSecret}
                                totalAmount={totalAmount}
                                handleClose={handleClose}
                                action="Epin_recharge"
                                setclientSecret={setclientSecret} />
                            :
                            <Form>


                                {/* <Form.Group className="mb-3">
                                    <Form.Label>{t("amount")}</Form.Label>
                                    <Select
                                        isSearchable={true}
                                        id="amountCount"
                                        options={epinCurrencyConverter(amounts, conversionFactor) ?? ""}
                                        value={formState.amount}
                                        onChange={(value) => handleSelectChange('amount', value)}
                                        labelledBy="Select"
                                        placeholder="Select recharge amount"
                                        className={
                                            errorMessage?.amount !== null ? errorClassName : ""
                                        }
                                    />
                                    <div className="required_dropDown">
                                        {errorMessage?.amount ? errorMessage?.amount : ""}
                                    </div>
                                </Form.Group> */}

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("amount")}</Form.Label>
                                    <Form.Control
                                        type="number"
                                        id="amount"
                                        value={formState.amountCount}
                                        placeholder="Enter amount"
                                        name="amount"
                                        required
                                        onChange={(e) => handleChange(e)}
                                        isInvalid={errorMessage?.amount !== null}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errorMessage?.amount}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("epin")}</Form.Label>
                                    <Select

                                        id="amountCount"
                                        options={purchasedEpin}
                                        value={formState.epin}
                                        onChange={(value) => handleSelectChange('epin', value)}
                                        labelledBy="Select"
                                        placeholder="Select your epin"
                                        className={
                                            errorMessage?.epin !== null ? errorClassName : ""
                                        }
                                    />
                                    <div className="required_dropDown">
                                        {errorMessage?.epin ? errorMessage?.epin : ""}
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("paymentMethod")}</Form.Label>
                                    <Select

                                        id="payment-method"
                                        options={paymentMethodss}
                                        value={formState.paymentMethod}
                                        onChange={(value) => handleSelectChange('paymentMethod', value)}
                                        labelledBy="Select"
                                        placeholder="Select payment method"
                                        className={
                                            errorMessage?.paymentMethod !== null ? errorClassName : ""
                                        }
                                    />
                                    <div className="required_dropDown">
                                        {errorMessage?.paymentMethod ? errorMessage?.paymentMethod : ""}
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" style={{ display: 'none' }}>
                                    <Form.Label>{t("expiryDate")}</Form.Label>
                                    <Form.Control
                                        id="expiryDate"
                                        type="date"
                                        value={formState.expiryDate}
                                        placeholder="Expiry Date"
                                        onChange={(e) => handleChange(e)}
                                        isInvalid={errorMessage?.expiryDate !== null}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errorMessage?.expiryDate}
                                    </Form.Control.Feedback>
                                </Form.Group>




                                <SubmitButton
                                    isSubmitting={!isFormValid() || rechargeMutation.isLoading}
                                    click={handleSubmit}
                                    text={rechargeMutation.isLoading ? "Processing..." : formState?.paymentMethod?.label == "E-wallet" ? 'Submit' : "Next"}
                                    className={"btn btn-primary submit_btn"}
                                />
                            </Form>
                        }
                    </>
                }


            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default EpinRecharge