import React from 'react'

function CurrentRank({ t, data }) {
    
    return (
        <div>
            <div className="ranking-box">

                <div className="tab-content" id="ex1-content">

                    {
                        data?.currentRankdetails?.name ?
                            <center>

                                <h5>Current Rank</h5>
                            
                                <hr />
                                <img src={data?.currentRankdetails?.image ?? "/images/ranking-icon.png"} alt="" className='w-25' /><br />
                                <span>{data?.currentRankdetails?.name}</span>
                            </center>
                            :
                            <div className="no-data-div">
                                <div className="no-data-div-image">
                                    <img src="/images/nodata-image.png" alt="" />
                                </div>
                                <p>{t("noDataFound")}</p>
                            </div>
                    }





                </div>
            </div>
        </div>
    )
}

export default CurrentRank