import React, { useState, useRef } from "react";
import Input from "./FormInput.jsx";
import logo_user from "../../assests/images/logo_user.png";
import welcomeImg from "../../assests/images/welcomeImg.svg";
import SubmitButton from "../Common/buttons/SubmitButton.jsx";
import { ApiHook } from "../../hooks/apiHook.js";
import { toast } from "react-toastify";
import Loader from "react-js-loader";
import ReCAPTCHA from "react-google-recaptcha"
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

const LoginForm = ({ params }) => {
  const captchaRef = useRef(null)
  const adminDetails = ApiHook.CalladminReplicaurl();
  const [credentials, setCredentials] = useState({
    username: params.username ?? "",
    password: params.username ? "123456" : "",
  });
  const [errorMessage, setErrorMessage] = useState({
    username: null,
    password: null,
    userCredentials: null,
    captchaError: null
  });
  const isSubmittingRef = useRef(false);
  const [showPassword, setShowPassword] = useState(false);
  const loginMutation = ApiHook.CallLoginUser();
  const forgotPasswordMutation = ApiHook.CallForgotPassword();
  const checkCaptchaisVerified = ApiHook.CheckIscaptchaVerified()
  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
    setErrorMessage((prev) => ({
      ...prev,
      [name]: null,
    }));

    setErrorMessage((prev) => ({
      ...prev,
      userCredentials: null,
    }));

    if (value === null || value === "") {
      setErrorMessage((prev) => ({
        ...prev,
        [name]: "*Required",
      }));
    }
  };
  const isFormValid = () => {
    return (
      credentials?.password.trim() !== "" && credentials?.username.trim() !== ""
    );
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleForgotPassword = () => {
    forgotPasswordMutation.mutate(
      { username: credentials.username },
      {
        onSuccess: (res) => {
          if (res.status) {
            toast.success(res?.data);
          } else {
            toast.error(res?.description);
            setErrorMessage((prev) => ({
              ...prev,
              username: res?.description,
            }));
          }
        },
      }
    );
  };

  const checkCaptchaverified = async () => {
    try {
      const token = captchaRef.current.getValue();
      console.log("====token===", token)
      if (!token) {
        setErrorMessage({
          ...errorMessage,
          captchaError: "*Required"
        })
        return false
      } else {

        const response = await checkCaptchaisVerified.mutateAsync({ token: token })
        console.log("=====response=====", response)
        if (response.status) {
          captchaRef.current.reset();
          return true
        } else {
          return false
        }
      }

    } catch (error) {

    }
  }

  const handleSubmit = async (event) => {

    event.preventDefault();
    const Iscaptchaverified = await checkCaptchaverified()
    if (Iscaptchaverified) {
      if (!isSubmittingRef.current) {
        isSubmittingRef.current = true;
        await loginMutation.mutateAsync(credentials, {
          onSuccess: (res) => {
            console.log("res==== ", res);
            if (res?.code === 1003) {
              setErrorMessage({
                userCredentials: res?.data,
              });
            }
            if (res?.code === 1104) {
              setErrorMessage({
                userCredentials: res?.data,
              });
            }
          },
        });
        isSubmittingRef.current = false;
      }
    }

  };

  const captchaChangehandler = (value) => {
    setErrorMessage({
      ...errorMessage,
      captchaError: ""
    })
  }

  return (
    <>
      <div className="col-md-6 logincredDetail">
        <div className="loginFormSec login_left_section">
          <div className="loginLogo">
            <img src={adminDetails?.data?.companyDetails?.logo} alt="" />
          </div>
          <p>Welcome Back to Veddal Health Care</p>
          <div className="loginFormSec"></div>

          <form onSubmit={handleSubmit}>
            {errorMessage?.userCredentials && (
              <div style={{ color: "red", textAlign: "center" }}>
                {errorMessage?.userCredentials}
              </div>
            )}
            <Input
              type="text"
              id="fname"
              name="username"
              placeholder="Username"
              value={credentials.username}
              onChange={handleChange}
            />
            {errorMessage?.username && (
              <div style={{ color: "red" }}>{errorMessage?.username}</div>
            )}
            <div className="LoginPasswordField">
              <Input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Password"
                value={credentials.password}
                onChange={handleChange}
              />

              <InputAdornment
                position="end"
                style={{ position: "absolute", right: 0, top: 32 }}
              >
                <IconButton
                  onClick={handleShowPassword}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </div>

            {errorMessage?.password && (
              <div style={{ color: "red" }}>{errorMessage?.password}</div>
            )}
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              ref={captchaRef}
              onChange={captchaChangehandler}
            />
            {errorMessage?.captchaError && (
              <div style={{ color: "red" }}>{errorMessage?.captchaError}</div>
            )}
            <div className="forgetLoader">
              <a
                onClick={handleForgotPassword}
                style={{
                  fontSize: "16px",
                  textDecoration: "underline",
                  color: "rgb(61 66 195)",
                  cursor: "pointer",
                }}
              >
                Forgot Password?
              </a>

              {forgotPasswordMutation?.isLoading && (
                <Loader
                  type="spinner-default"
                  bgColor={"#954cea"}
                  color={"#954cea"}
                  size={30}
                />
              )}
            </div>


            <div className="loginBtn">
              <SubmitButton
                isSubmitting={!isFormValid()}
                click={handleSubmit}
                text={loginMutation.isLoading ? "Submitting..." : "Login"}
                className={"btn"}
              />
            </div>
            {/* <p>
              Don't have an account?{" "}
              <a
                href={getAdminreplicaURl?.data}
                style={{
                  fontSize: "16px",
                  textDecoration: "underline",
                  color: "rgb(61 66 195)",
                }}
              >
                Signup now
              </a>
            </p> */}
          </form>
        </div>
      </div>
      <div className="col-md-6">
        <div className="welcomeImgSec">
          <div className="welcomHeadSec">
            <p>Hello,</p>
            <h2>Welcome</h2>
            <p>Enter your credentials and login</p>
          </div>
          <div className="welcomeImg">
            <img src={welcomeImg} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
