import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import moment from 'moment';

function Rank_report({ t, rankData, setrank, selectedRank, loader }) {
  console.log("==rankData====", rankData)
  const [ranks, setranks] = useState(rankData?.tabs ?? '')
  const [activeTab, setactiveTab] = useState(selectedRank)
  console.log("====ranks==", ranks)
  const handleTabClick = (activeTab) => {
    setactiveTab(activeTab)
    setrank(activeTab)
  }

  const formatNumber = (number) => {
    if (number >= 1000000) {
      return `${(number / 1000000).toFixed(2)}m`;
    } else if (number >= 1000) {
      return `${(number / 1000).toFixed(2)}k`;
    } else {
      return number;
    }
  };

  console.log("=======activeTab=====", activeTab)
  console.log("===vakuye==", rankData?.extraData?.MINIMUM_REQUIRED_RANK_FOR_PV)
  const valueCheck = (value) => {
    console.log("====value==", value)
    if (value == null) {
      return 'NA'
    } else {
      return value
    }
  }
  return (
    <div>
      <div className='rank-validity'> <span> {t('rankValidity')} : {rankData?.rankDatevalidity}</span></div>
     

      <ul
        className="teamPerfomance_tab nav nav-tabs mb-3"
        id="ex1"
        role="tablist"
      >

        {ranks &&
          ranks.map((item) => {
            console.log("====item====", item)
            return (
              <>
                <li className="nav-item" role="tablist">
                  <Link
                    className={`nav-link${activeTab === item ? " active" : ""
                      }`}
                    id={`ex1-tab-${item}`}
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected={activeTab === item}
                    onClick={() => handleTabClick(item)}
                  >
                    {t(item)}
                  </Link>
                </li>
              </>
            );
          })}


      </ul>


      <div className="tab-content" id="ex1-content">

        {rankData && rankData?.rank?.length > 0 && (
          console.log("===entering here===="),

          <>
            <div className="car_reward_title">
              <u> {t("Details")}</u>
              <br />

              <span>{t("status")} : </span>

              {
                rankData.rankAchievedstatus ?
                  <span>{t("achieved")}  <i class="fa-solid fa-check" style={{ color: 'green' }}></i></span> :
                  <span>{t("notachieved")} <i class="fa-solid fa-xmark" style={{ color: 'red' }}></i> </span>
              }
              <br />


              {
                rankData.extraData &&
                  activeTab === "RETAIL_PURCHASE_INFO" ?
                  <span>{t('dateRange')} : {moment(rankData?.extraData?.MONTHLY_RETAIL_PV_DATE_RANGE.START_DATE).format('DD-MM-YYYY')} {t('to')} {moment(rankData?.extraData?.MONTHLY_RETAIL_PV_DATE_RANGE.END_DATE).format('DD-MM-YYYY')}</span>
                  // <span>Date Range :{rankData?.extraData?.MONTHLY_RETAIL_PURCHASE_TOTAL_PV[0].DATE_RANGE} </span>
                  : activeTab === "GET_LEFT_RIGHT_CARRY_INFO" ?
                    <span>{t('minimumRequiredPvforRank')} : {valueCheck(rankData?.extraData?.MINIMUM_REQUIRED_RANK_FOR_PV)}</span>
                    : activeTab === "DOWN_LINE_RANK_COUNT_STATUS_INFO" ?
                      <span>{t('requiredRank')} : {valueCheck(rankData?.extraData?.REQUIRED_RANK)}</span>
                      : activeTab === "MINIMUM_DOWNLINE_COUNT_STATUS_INFO" ?
                        <span>{t('basedOn')}: {rankData?.extraData?.BASED_ON}</span>
                        : activeTab === "MINIMUM_LEG_COUNT_STATUS_INFO" ?

                          <span>{t("info")} : {rankData?.extraData?.INFO}</span>
                          :

                          ''

              }



            </div>
            <hr />
            <div>
              <div className="rightendHeading">
                <span>{t("achieved")}</span>&nbsp;&nbsp;
                <span>{t("required")}</span>
              </div>



              {Object.entries(rankData?.rank[0]).map(
                ([key, value]) => {

                  return (
                    <>
                      <div className="teammbrs_cnt_row">
                        <div className="teammbrs_cnt_name_dtl">

                          <div className="earning_expence_row">{t(key)}</div>



                        </div>


                        <div style={{ color: "rgb(3, 173, 71)" }}>
                          <div className="teammbrs_cnt_name_dtl">

                            <div className="top_recuirters_num_travel">
                              <div
                                style={{ display: "inline-flex", gap: "2%" }}
                              >

                                <span
                                  className="achieved-span"
                                  style={{ background: "#7cbfe8" }}
                                >
                                  {formatNumber(Number(value.achieved))}
                                </span>
                                <span>
                                  {formatNumber(Number(value.required))}
                                </span>
                              </div>
                            </div>

                          </div>


                        </div>
                      </div>
                    </>
                  );
                }
              )}
              {/* </>
                }
                */}


            </div>
          </>
        )}



      </div>

    </div>
  )
}

export default Rank_report