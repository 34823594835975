import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import packageDEfault from '../../assests/images/package.png'
function PackageInfo({ data }) {
  console.log("=====packageinfo====", data)
  const [activeTab, setactiveTab] = useState("currentpack")
  const { t } = useTranslation()

  const handleTabChange = (tabId) => {
    console.log("=====tabid==========", tabId)
    setactiveTab(tabId);
  };
  console.log("=data====", data)
  const formatNumber = (number) => {
    if (number >= 1000000) {
      return `${number / 1000000}m `;
    } else if (number >= 1000) {
      return `${number / 1000}k `;
    } else {
      return number;
    }
  };

  return (
    <div className="col-md-4">
      <div className="ranking-box">
        <div className="joinings_viewBox_head">
          <h5>{t('packageInfo')}</h5>
        </div>
        <ul
          className="teamPerfomance_tab nav nav-tabs mb-3"
          id="ex1"  // Unique ID for the tab list
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <Link
              className={`nav-link ${activeTab === "currentpack" ? "active" : ""}`}
              id="ex1-tab-3"
              data-bs-toggle="tab"
              role="tab"
              aria-selected={activeTab === "currentpack" ? true : false}
              onClick={() => (setactiveTab("currentpack"))}
            >
              {t("currentPack")}
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className={`nav-link ${activeTab === "nextpackage" ? "active" : ""}`}
              id="ex1-tab-3"
              aria-selected={activeTab === "nextpackage" ? true : false}
              data-bs-toggle="tab"
              role="tab"
              onClick={() => (setactiveTab("nextpackage"))}
            >
              {t("next-package")}
            </Link>
          </li>
        </ul>
        <div className="tab-content" id="ex1-content">
          <p>{t("entryDetails")}</p>
          {/* {
            data?.currentPackinfo?.Package==null?
            <div className="no-data-div">
              <div className="no-data-div-image">
                <img src="/images/nodata-image.png" alt="" />
              </div>
              <p>{t("noDataFound")}</p>
            </div>
            : */}
          <>
            <div
              className={`tab-pane ${activeTab === "currentpack" ? "active" : ""}`}
              id="currentpack"
              role="tabpanel"
              aria-labelledby="currentpack"
            >
              {/* Content for the "current-pack" tab */}


              {
                data?.currentPackinfo?.Package == null ?

                  <div className="no-data-div">
                    <div className="no-data-div-image">
                      <img src="/images/nodata-image.png" alt="" />
                    </div>
                    <p>{t("noDataFound")}</p>
                  </div>
                  :
                  <div className={`ranking-icon rankingbox`}>
                    <img src={data?.currentPackinfo?.Package?.image??packageDEfault} alt="" />
                    <p></p>
                    <span>{data?.currentPackinfo?.Package?.name}</span>
                  </div>


              }

            </div>

            <div
              className={`tab-pane ${activeTab === "nextpackage" ? "active" : ""}`}
              id="nextpackage"
              role="tabpanel"
              aria-labelledby="nextpackage"
            >
              {/* Content for the "next-package" tab */}
              <div className="top_recuirters_section">
                {
                  data?.nextPackInfo?.length === 0 ?
                    <div className="no-data-div">
                      <div className="no-data-div-image">
                        <img src="/images/nodata-image.png" alt="" />
                      </div>
                      <p>{t("noDataFound")}</p>
                    </div>
                    :
                    <>
                      <div className='top_recuirters_heading_section'>
                        <span>{t("name")}</span>
                        <span style={{ float: "right" }}>{t("requiredPv")}</span>
                      </div>
                      {data?.nextPackInfo?.map((data, index) => (
                        <div key={index} className="teammbrs_cnt_row">
                          <div className="teammbrs_cnt_img">
                            <img src={data.image} alt="" />
                          </div>
                          <div className="teammbrs_cnt_name_dtl">
                            <div className="teammbrs_cnt_name">
                              {data.name}
                            </div>
                            <div className="top_recuirters_num">
                              <span>{formatNumber(Number(data.remainingPv))}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                }

              </div>
            </div>

          </>
          {/* // } */}

        </div>
      </div>
    </div>


  )
}

export default PackageInfo