import React from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripecheckoutForm from '../../components/payment/StripecheckoutForm';
import { STRIPE_PK } from '../../config/config';

function Stripe({ clientSecret, totalAmount, action, handleClose, setclientSecret, handleSubmitFinish }) {

  // const stripePromise = loadStripe('pk_test_51OPlMBHNF2myN32T4ULWs3Q416p4Nn9Btkq2qIQWuknhkr7C2WxxwkDdddL78hh2j7lcMYeOWCbzJkQ974D2sYmx003HjL2aIE');
  const stripePromise = loadStripe(STRIPE_PK);


  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <StripecheckoutForm
        totalAmount={totalAmount}
        action={action}
        handleClose={handleClose}
        setclientSecret={setclientSecret}
        handleSubmitFinish={handleSubmitFinish}
      />
    </Elements>
  )
}

export default Stripe