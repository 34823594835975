import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  loginPasswordValidator,
  validateAge,
  loginUsernameValidator,
  passwordRules,
} from "../../Validator/register";
import PasswordChecklist from "react-password-checklist";
import { ApiHook } from "../../hooks/apiHook";
import { BASE_URL } from "../../config/config";
import Stripe from "../../views/payments/Stripe";
import { useEffect } from "react";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { useParams } from "react-router";
import errorPic from '../../assests/images/3973481.jpg'
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";


const ReplicaRegisterForm = ({
  activeStep,
  setActiveStep,
  handleToggleRegisterModal,
  animateStepTransition,
  data,
}) => {
  const [clientSecret, setClientSecret] = useState({ status: false, data: "" });

  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("");
  const [states, setStates] = useState([]);
  const [submitButtonActive, setSubmitButtonActive] = useState(true);
  const [file, setFile] = useState(null);
  const [selectedProducts, setselectedProducts] = useState([])
  const [fileResponse, setFileResponse] = useState({
    success: null,
    error: null,
  });
  const [idProof, setidProof] = useState({
    file: null,
    errorMessage: "",
  });
  const [showPswrd, setshowPswrd] = useState(false)
  const [productQuantities, setProductQuantities] = useState({});
  const getAmountId = ApiHook.CallGetepinamount();
  const {
    register,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm();
  const getUserid = ApiHook.CallgetrandomUsername();
  const { fatherName, position } = useParams()
  const isPositionfilled = useSelector((state) => state?.replica?.placementAlert)
  console.log("isPositionFilledAlert:", isPositionfilled);
  console.log("====placementdata===", fatherName, position)

  const formValues = watch();
  // console.log(formValues);
  const createIntent = ApiHook.CallPaymentIntent();
  const regAmount = useSelector(
    (state) => state?.dashboard?.appLayout?.configuration?.regAmount
  );
  const createIntentData = async () => {
    const payload = {
      email: formValues.email,
      desc: formValues.username,
      amount: formValues?.totalAmount ?? regAmount.toFixed(2) ?? 0,
    };
    await createIntent.mutateAsync(payload).then((response) => {
      console.log("res --- ", response.client_secret);

      setClientSecret((prev) => ({
        ...prev,
        status: true,
        data: response.client_secret,
      }));
    });
  };

  const handleShowPassword = () => {
    setshowPswrd(!showPswrd)
  }

  const steps = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"];
  const fileChangehandler = (e) => {
    setidProof({
      ...idProof,
      file: e.target.files[0],
      errorMessage: "",
    });
  };
  // ------------------------------------------- API ------------------------------------------
  const checkUsernameField = ApiHook.CallRegisterFieldsCheck();
  const checkEmailField = ApiHook.CallRegisterFieldsCheck();
  const Upload = ApiHook.CallReplicaBankRecieptUpload(
    "register/replica",
    formValues?.username,
    data?.sponsorData?.id,
    setSubmitButtonActive,
    setValue,
    setFileResponse
  );
  const replicaRegisterMutation = ApiHook.CallReplicaRegisterPost();

  // ---------------------------------- Functions ------------------------------------
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );

  const handleNext = async () => {
    let isValid;
    if (
      activeStep === 3 &&
      !checkUsernameField.data?.data?.status &&
      checkUsernameField.data?.data?.field === "username" &&
      checkUsernameField.data?.data?.code === 1117
    ) {
      isValid = false;
    } else if (
      activeStep === 2 &&
      !checkEmailField.data?.data?.status &&
      checkEmailField.data?.data?.field === "email" &&
      checkEmailField.data?.data?.code === 1117
    ) {
      isValid = false;
    } else if (activeStep === 2 && !idProof.file) {
      setidProof({
        ...idProof,
        errorMessage: "Id proof required",
      });
    } else {
      isValid = await trigger();
    }
    if (isValid) {
      const nextStep = activeStep + 1;
      animateStepTransition(nextStep);
      setActiveStep(nextStep);
    }
  };
  const getPurchaseDetails = async () => {
    try {
      let epinData;
      const getAmountcode = await getAmountId.mutateAsync({
        amount: formValues.regAmount,
      });
      console.log("====getAmountcode====", getAmountcode?.data);
      if (getAmountcode.status) {
        setValue("amountCode", [getAmountcode?.data?.id]);
        setValue("epinCount", "1");
        setValue("expiryDate", "9999-01-01");
        setValue("transactionPassword", "12345678");
        //  epinData = {
        //     "amountCode": [getAmountcode?.data?.id],
        //     "epinCount": "1",
        //     "expiryDate": "9999-01-01",
        //     "transactionPassword": "12345678"
        //   }
      }

      // return epinData
    } catch (error) {
      console.log(error);
    }
  };
  console.log("formvalues==============", formValues);

  useEffect(() => {
    if (formValues.regAmount) {
      getPurchaseDetails();
    }

  }, [formValues.regAmount])
  const handleBack = () => {
    const prevStep = activeStep - 1;
    animateStepTransition(prevStep);
    setActiveStep(prevStep);
  };
  console.log("formvalues ==== ", formValues);

  const handleSubmit = async (stripeToken) => {
    let formdata = new FormData();

    if (data?.sponsorData?.username) {
      setValue("placement", data?.sponsorData?.username);
      formValues.placement = data?.sponsorData?.username;
      setValue("referralId", data?.sponsorData?.id);
      formValues.referralId = data?.sponsorData?.id;
    }
    Object.entries(formValues)?.map(([key, value]) => {
      if (key === "product") {
        return formdata.append(key, JSON.stringify(value));
      } else {
        return formdata.append(key, value);
      }
    });
    if (formValues.paymentType == 5) {
      formdata.append("stripeToken", stripeToken);
    }
    formdata.append('fatherName', fatherName);
    formdata.append('legPosition', position);
    formdata.append("file", idProof.file);

    console.log("Finish button clicked", formdata);
    replicaRegisterMutation.mutate(formdata);
  };

  const handleProductClick = (productId, productName, pv, price) => {

    let updatedSelectedProducts,
      totalAmount
    if (selectedProducts.some((product) => product.id === productId)) {
      let filteredData = selectedProducts.filter((data) => data.id !== productId)
      setselectedProducts(filteredData)
      updatedSelectedProducts = filteredData
    } else {
      updatedSelectedProducts = [
        ...selectedProducts,
        { id: productId, name: productName, price: price, quantity: 1, pv: pv }
      ];
    }
    setProductQuantities({
      ...productQuantities,
      [productId]: 1,
    });

    console.log("==updatedSelectedProducts====", updatedSelectedProducts)
    setselectedProducts(updatedSelectedProducts)
    // const totalAmount = Number(price) + JSON.parse(regAmount);

    // let productPrice = updatedSelectedProducts.reduce((acc, product) => {
    //   return acc + Number(product.price)
    // }, 0);
    // totalAmount = Number(productPrice) + JSON.parse(regAmount)
    // setValue("totalAmount", totalAmount.toFixed(2));
    setValue(
      "product",
      // { id: productId, name: productName, price: price },
      updatedSelectedProducts,
      { shouldValidate: true }
    );
    setValue("pv", pv);

    setValue("regAmount", data?.regFee);
  };

  const handleQuantityChange = (e, productId, action) => {
    e.preventDefault();
    const currentQuantity = productQuantities[productId] || 1;
    console.log("==currentQuantity==", currentQuantity)
    let newQuantity;
    if (action === 'add') {
      newQuantity = currentQuantity + 1;
    } else if (action === 'minus' && currentQuantity > 1) {
      newQuantity = currentQuantity - 1;
    } else {
      newQuantity = currentQuantity;
    }
    setProductQuantities({
      ...productQuantities,
      [productId]: newQuantity,
    });
    let updatedProduct = selectedProducts.map((item, index) => {
      console.log("===items", item)
      if (item.id === productId) {
        return {
          ...item,
          quantity: newQuantity
        }
      }
      return item

    })
    setselectedProducts(updatedProduct)
    setValue(
      "product",
      updatedProduct,
      { shouldValidate: true }
    );

    console.log("==updatedProduct====", updatedProduct)




  };

  const handlePaymentTabClick = (tabId) => {
    setActiveTab(tabId);
    if (tabId === 3) {
      setSubmitButtonActive(false);
    } else if (tabId === 5) {
      createIntentData();
    } else {
      setSubmitButtonActive(true);
    }
    setValue("paymentType", tabId, { shouldValidate: true }); // Set the selected payment
    if (!data?.modStatus?.productStatus && false) {
      setValue("totalAmount", data?.regFee); // regAmount is added to total amount. If there is no product
    }
  };

  const handleEmailCheck = async (item) => {
    const { name, value } = item;
    checkEmailField.mutate({ field: name, value: value });
  };

  const handleUsernameCheck = async (item) => {
    const { name, value } = item;
    checkUsernameField.mutate({ field: name, value: value });
  };

  const handleCustomField = (id, value) => {
    if (formValues.customFields?.length > 0) {
      const existingIndex = formValues.customFields.findIndex(
        (item) => item.id === id
      );
      if (existingIndex !== -1) {
        const updatedCustomField = [...formValues.customFields];
        updatedCustomField[existingIndex] = { id: id, value: value };
        setValue("customFields", updatedCustomField);
      } else {
        setValue("customFields", [
          ...formValues.customFields,
          { id: id, value: value },
        ]);
      }
    } else {
      setValue("customFields", [{ id: id, value: value }]);
    }
  };

  const handleFileChange = (event) => {
    setFileResponse({
      success: null,
      error: null,
    });
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = () => {
    if (file) {
      Upload.mutate(file);
    }
  };

  const handleCountry = async (selectedCountry, statesData) => {
    const response = await getUserid.mutateAsync({
      countryId: selectedCountry,
    });
    console.log("===responsepp===", response);
    if (response.status) {
      setValue("username", response?.data?.userId);
    } else {
    }
    if (selectedCountry) {
      setValue("country", selectedCountry);
      formValues.country = selectedCountry;
    }
    statesData?.map((value) => {
      if (parseInt(selectedCountry) === value.id) {
        setStates(value.States);
      }
    });
  };
  const calculateTotalAmount = async () => {
    let totalAmount = selectedProducts.reduce((acc, product) => {

      let amount = product.price * product.quantity
      return acc + Number(amount);
    }, 0);

    totalAmount += Number(data?.regFee);

    setValue("totalAmount", totalAmount.toFixed(2));
  }

  const calculatePv = async () => {
    let totalPv = selectedProducts.reduce((acc, product) => {
      let pv = product.pv * product.quantity;
      return acc + Number(pv);
    }, 0);
    setValue("pv", totalPv)
  }
  useEffect(() => {
    calculateTotalAmount()
    calculatePv()
  }, [selectedProducts])

  return (
    <div className="main-content-regsiter" style={{ margin: "65px" }}>
      {
        isPositionfilled ?
          <div>


            <img src={errorPic} className="w-50" /><br />

            <span>We regret to inform you that the placement you requested has already been filled by another user. We apologize for any inconvenience this may cause.</span>

          </div>


          :
          <>

            <div className="row justify-content-center pt-0 p-4" id="wizardRow">




              <div className="col-md-12 text-center">
                <div className="wizard-form py-4 my-2">
                  <ul id="progressBar" className="progressbar px-lg-5 px-0">
                    {steps.map((step, index) => (
                      <li
                        key={`step-${index + 1}`}
                        id={`progressList-${index + 1}`}
                        className={`d-inline-block w-20 position-relative text-center float-start progressbar-list ${index <= activeStep - 1 ? "active" : ""
                          }`}
                      >
                        {step}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div id="animation">
              {activeStep === 1 && (
                <div className="row row_top justify-content-center" id="cardSection">
                  <div className="col-lg-12 col-md-12">
                    <div className="regsiter_step_1_view">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="right_product_selection_bx">
                            <div className="regsiter_step_1_view_left_sec_head">
                              {data?.registrationSteps &&
                                t(
                                  `${data?.registrationSteps[activeStep - 1]?.label}`
                                )}
                            </div>
                            <div className="right_product_lsting_section">
                              <div className="row">
                                {!!data?.regData && (
                                  <span>
                                    {t("reg_amount_desc", {
                                      amount: `$ ${data?.regData}`,
                                    })}
                                  </span>
                                )}
                                {data?.regPack?.map((product, index) => (
                                  <div className="col-md-4" key={index}>
                                    <div
                                      className={`right_product_box card ${selectedProducts.some((products) => products.id === product.id)
                                        ? "active-card"
                                        : ""
                                        }`}
                                      {...register("product", { required: true })}
                                      onClick={() =>
                                        handleProductClick(
                                          product.id,
                                          product.name,
                                          product.pairValue,
                                          product.price
                                        )
                                      }
                                    >
                                      <div className="right_product_box_image">
                                        <img
                                          src={
                                            product?.image ?? "/images/product1.jpg"
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div className="right_product_box__dtails">
                                        <div className="right_product_box__head">
                                          {product?.name}
                                        </div>
                                        <div className="right_product_box__dsc">
                                          <strong id="price">{`${userSelectedCurrency?.symbolLeft
                                            } ${CurrencyConverter(
                                              product?.price,
                                              conversionFactor
                                            )}`}</strong>


                                          <br />
                                          {"PV - "}
                                          {product?.pairValue}
                                        </div>
                                      </div>
                                      <input
                                        className=""
                                        type="text"
                                        disabled={true}
                                        style={{ display: 'none' }}

                                      />
                                      <div className="checkout_cnt_qty_btn_sec_replica">
                                        <button
                                          id="minus"
                                          className="checkout_cnt_qty_btn_replica"
                                          disabled={selectedProducts.some((products) => products.id === product.id) ? false : true}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleQuantityChange(e, product?.id, "minus");
                                          }}
                                        >
                                          <i id="minus" className="fa fa-minus"></i>
                                        </button>

                                        <input
                                          className="checkout_cnt_qty_input"
                                          type="text"
                                          disabled={true}
                                          value={productQuantities[`${product.id}`] ?? 0}
                                        // value="5364"
                                        />

                                        <button
                                          id="plus"
                                          className="checkout_cnt_qty_btn_replica"
                                          disabled={selectedProducts.some((products) => products.id === product.id) ? false : true}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleQuantityChange(e, product?.id, "add");
                                          }}
                                        >
                                          <i id="plus" className="fa fa-plus"></i>
                                        </button>

                                      </div>

                                    </div>
                                  </div>
                                ))}
                              </div>
                              {errors?.product && (
                                <span className="error-message-validator">
                                  {t("this_field_is_required")}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                      onClick={() => handleNext()}
                    >
                      {t("BuyNow")}
                    </button>
                  </div>
                </div>
              )}
              {activeStep === 2 && (
                <div className="row row_top justify-content-center form-business">
                  {/* {data?.modStatus?.mlmPlan === "Binary" && (
      <>
        <div className="regsiter_step_1_view_left_sec_head">
          {t("placement")}
        </div>
        <div className="regsiter_second_step_bx reg_select_pos">
          <div className="regsiter_step_1_view_left_btn_row mt-0">
            <label
              className={`regsiter_step_1_view_left_btn ${formValues.position === "L" ? "active" : ""
                }`}
            >
              <input
                type="radio"
                name="position"
                value={"L"}
                {...register("position", {
                  required: true,
                })}
              />
              {t("left")}
            </label>
            <label
              className={`regsiter_step_1_view_left_btn ${formValues.position === "R" ? "active" : ""
                }`}
            >
              <input
                type="radio"
                name="position"
                value={"R"}
                {...register("position", {
                  required: true,
                })}
              />
              {t("right")}
            </label>
          </div>
          {errors["position"] && formValues.position === null && (
            <span className="error-message-validator">
              {t("this_field_is_required")}
            </span>
          )}
        </div>
      </>
    )} */}
                  <div className="regsiter_step_1_view_left_sec_head">
                    {data?.registrationSteps &&
                      t(`${data?.registrationSteps[activeStep - 1]?.label}`)}
                  </div>
                  <div className="regsiter_second_step_bx">
                    <div className="row">
                      {data?.contactInformation?.contactField?.map((item, index) => (
                        <div className="col-md-6" key={index}>
                          <div className="regsiter_form_box">
                            <label htmlFor={item.code}>
                              {t(`${item.code}`)}
                              <span className="text-danger" hidden={!item.required}>
                                ٭
                              </span>
                            </label>
                            {item.type === "text" &&
                              item.code !== "state" &&
                              !item.options && (
                                <input
                                  id={item.code}
                                  name={item.code}
                                  type="text"
                                  className={`form-control ${errors[item.code] ? "error-field" : ""
                                    }`}
                                  placeholder={item.placeholder}
                                  {...register(item.code, {
                                    required: {
                                      value: item.required,
                                      message: t("this_field_is_required"),
                                    },
                                    // pattern: {
                                    //   value: /^[A-Za-z0-9]+$/,
                                    //   message: t("invalid_format"),
                                    // },
                                  })}
                                  onBlur={async () => await trigger(item.code)}
                                />
                              )}
                            {item.type === "email" && (
                              <>
                                <input
                                  id={item.code}
                                  name={item.code}
                                  type="email"
                                  className={`form-control ${(!checkEmailField.data?.data?.status &&
                                    checkEmailField.data?.data?.field === "email" &&
                                    checkEmailField.data?.data?.code === 1117) ||
                                    errors[item.code]
                                    ? "error-field"
                                    : ""
                                    }`}
                                  placeholder={item.placeholder}
                                  {...register("email", {
                                    required: {
                                      value: item.required,
                                      message: t("this_field_is_required"),
                                    },
                                    pattern: {
                                      value:
                                        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                                      message: t("invalid_email_format"),
                                    },
                                  })}
                                  onChangeCapture={async (e) => {
                                    handleEmailCheck(e.target);
                                  }}
                                  onBlur={async () => await trigger(item.code)}
                                />
                                {!checkEmailField.data?.data?.status &&
                                  checkEmailField.data?.data?.field === "email" &&
                                  checkEmailField.data?.data?.code === 1117 && (
                                    <span className="error-message-validator">
                                      {t("email_exists")}
                                    </span>
                                  )}
                              </>
                            )}
                            {item.type === "date" && (
                              <>
                                <input
                                  id={item.code}
                                  name={item.code}
                                  type="date"
                                  className={`form-control ${errors[item.code] ? "error-field" : ""
                                    }`}
                                  placeholder={item.placeholder}
                                  {...register(item.code, {
                                    required: {
                                      value: item.required,
                                      message: t("this_field_is_required"),
                                    },
                                    validate: (value) => validateAge(value, t),
                                  })}
                                  onBlur={async () => await trigger(item.code)}
                                />
                                {errors.date_of_birth && (
                                  <span className="error-message-validator">
                                    {errors.date_of_birth.message}
                                  </span>
                                )}
                              </>
                            )}
                            {item.type === "file" && (
                              <>
                                <input
                                  id={item.code}
                                  name={item.code}
                                  type="file"
                                  className={`form-control ${idProof.errorMessage ? "error-field" : ""
                                    }`}
                                  placeholder={item.placeholder}
                                  onChange={fileChangehandler}
                                />
                                {idProof.file && (
                                  <div>
                                    {" "}
                                    <label>
                                      {" "}
                                      Selected file is : {idProof?.file?.name}
                                    </label>
                                  </div>
                                )}
                                {idProof.errorMessage && (
                                  <span className="error-message-validator">
                                    {idProof.errorMessage}
                                  </span>
                                )}
                              </>
                            )}
                            {item.type === "number" && (
                              <input
                                id={item.code}
                                name={item.code}
                                type="number"
                                className={`form-control ${errors[item.code] ? "error-field" : ""
                                  }`}
                                placeholder={item.placeholder}
                                {...register(item.code, {
                                  required: {
                                    value: item.required,
                                    message: t("this_field_is_required"),
                                  },
                                  minLength: {
                                    value: 5,
                                    message: t("min_length_of_5"),
                                  },
                                })}
                                onBlur={async () => await trigger(item.code)}
                              />
                            )}
                            {item.type === "text" && item.code === "gender" && (
                              <select
                                id={item.code}
                                name={item.code}
                                className={`form-control ${errors[item.code] ? "error-field" : ""
                                  }`}
                                {...register(item.code, {
                                  required: {
                                    value: item.required,
                                    message: t("this_field_is_required"),
                                  },
                                })}
                                onBlur={async () => await trigger(item.code)}
                              >
                                {item?.options?.map((option, optionIndex) => (
                                  <option key={optionIndex} value={option.value}>
                                    {option.title}
                                  </option>
                                ))}
                              </select>
                            )}
                            {item.type === "text" && item.code === "country" && (
                              <select
                                id={item.code}
                                name={item.code}
                                className={`form-control ${errors[item.name] ? "error-field" : ""
                                  }`}
                                {...register(item.code, {
                                  required: {
                                    value: item.required,
                                    message: t("this_field_is_required"),
                                  },
                                })}
                                onChange={(e) =>
                                  handleCountry(e.target.value, item.options)
                                }
                                onBlur={async () => await trigger(item.code)}
                              >
                                <option>{t("select_the_country")}</option>
                                {item?.options?.map((option, optionIndex) => (
                                  <option key={optionIndex} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            )}
                            {item.type === "text" && item.code === "state" && (
                              <select
                                id={item.code}
                                name={item.name}
                                className={`form-control ${errors[item.name] ? "error-field" : ""
                                  }`}
                                {...register(item.code, {
                                  required: {
                                    value: item.required,
                                    message: t("this_field_is_required"),
                                  },
                                })}
                                onBlur={async () => await trigger(item.name)}
                              >
                                {states?.map((option, optionIndex) => (
                                  <option key={optionIndex} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            )}
                            {errors[item.code] && !errors.date_of_birth && (
                              <span className="error-message-validator">
                                {errors[item.code].message}
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                      {data?.contactInformation?.customField?.map((item, index) => (
                        <div className="col-md-6" key={index}>
                          <div className="regsiter_form_box">
                            <label htmlFor={item.code}>
                              {item.value}{" "}
                              <span className="text-danger" hidden={!item.required}>
                                ٭
                              </span>
                            </label>
                            {item.type === "text" && !item.options && (
                              <input
                                id={item.code}
                                name={item.code}
                                type="text"
                                className={`form-control ${errors[item.code] ? "error-field" : ""
                                  }`}
                                placeholder={item.placeholder}
                                {...register(item.code, {
                                  required: {
                                    value: item.required,
                                    message: t("this_field_is_required"),
                                  },
                                  // pattern: {
                                  //   value: /^[A-Za-z0-9]+$/,
                                  //   message: t("invalid_format"),
                                  // },
                                })}
                                onChange={async (e) => {
                                  const inputValue = e.target.value; // Get the current value of the input field
                                  handleCustomField(item.id, inputValue);
                                  await trigger(item.code);
                                }}
                              />
                            )}
                            {item.type === "email" && (
                              <>
                                <input
                                  id={item.code}
                                  name={item.code}
                                  type="email"
                                  className={`form-control ${(!checkEmailField.data?.data?.status &&
                                    checkEmailField.data?.data?.field === "email" &&
                                    checkEmailField.data?.data?.code === 1117) ||
                                    errors[item.code]
                                    ? "error-field"
                                    : ""
                                    }`}
                                  placeholder={item.placeholder}
                                  {...register(item.code, {
                                    required: {
                                      value: item.required,
                                      message: t("this_field_is_required"),
                                    },
                                    pattern: {
                                      value:
                                        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                                      message: t("invalid_email_format"),
                                    },
                                  })}
                                  onChangeCapture={async (e) => {
                                    const inputValue = e.target.value; // Get the current value of the input field
                                    handleEmailCheck(e.target);
                                    handleCustomField(item.id, inputValue);
                                  }}
                                />
                                {!checkEmailField.data?.data?.status &&
                                  checkEmailField.data?.data?.field === "email" &&
                                  checkEmailField.data?.data?.code === 1117 && (
                                    <span className="error-message-validator">
                                      {t("email_exists")}
                                    </span>
                                  )}
                              </>
                            )}
                            {item.type === "date" && (
                              <>
                                <input
                                  id={item.code}
                                  name={item.code}
                                  type="date"
                                  className={`form-control ${errors[item.code] ? "error-field" : ""
                                    }`}
                                  placeholder={item.placeholder}
                                  {...register(item.code, {
                                    required: {
                                      value: item.required,
                                      message: t("this_field_is_required"),
                                    },
                                    validate: (value) => validateAge(value, t),
                                  })}
                                  onChange={async (e) => {
                                    const inputValue = e.target.value; // Get the current value of the input field
                                    handleCustomField(item.id, inputValue);
                                    await trigger(item.code);
                                  }}
                                />
                                {errors.date_of_birth && (
                                  <span className="error-message-validator">
                                    {errors.date_of_birth.message}
                                  </span>
                                )}
                              </>
                            )}
                            {item.type === "textarea" && (
                              <textarea
                                id={item.code}
                                name={item.code}
                                className={`form-control ${errors[item.code] ? "error-field" : ""
                                  }`}
                                placeholder={item.placeholder}
                                {...register(item.code, {
                                  required: {
                                    value: item.required,
                                    message: t("this_field_is_required"),
                                  },
                                })}
                                onChange={async (e) => {
                                  const inputValue = e.target.value; // Get the current value of the input field
                                  handleCustomField(item.id, inputValue);
                                  await trigger(item.code);
                                }}
                              />
                            )}
                            {item.type === "number" && (
                              <input
                                id={item.code}
                                name={item.code}
                                type="number"
                                className={`form-control ${errors[item.code] ? "error-field" : ""
                                  }`}
                                placeholder={item.placeholder}
                                {...register(item.code, {
                                  required: {
                                    value: item.required,
                                    message: t("this_field_is_required"),
                                  },
                                  minLength: {
                                    value: 5,
                                    message: t("min_length_of_5"),
                                  },
                                })}
                                onChange={async (e) => {
                                  const inputValue = e.target.value; // Get the current value of the input field
                                  handleCustomField(item.id, inputValue);
                                  await trigger(item.code);
                                }}
                              />
                            )}
                            {item.type === "text" && item.code === "gender" && (
                              <select
                                id={item.code}
                                name={item.code}
                                className={`form-control ${errors[item.code] ? "error-field" : ""
                                  }`}
                                {...register(item.code, {
                                  required: {
                                    value: item.required,
                                    message: t("this_field_is_required"),
                                  },
                                })}
                                onChange={async (e) => {
                                  const inputValue = e.target.value; // Get the current value of the input field
                                  handleCustomField(item.id, inputValue);
                                  await trigger(item.code);
                                }}
                              >
                                {item?.options?.map((option, optionIndex) => (
                                  <option key={optionIndex} value={option.value}>
                                    {option.title}
                                  </option>
                                ))}
                              </select>
                            )}
                            {item.type === "text" && item.code === "country" && (
                              <select
                                id={item.code}
                                name={item.code}
                                className={`form-control ${errors[item.name] ? "error-field" : ""
                                  }`}
                                {...register(item.code, {
                                  required: {
                                    value: item.required,
                                    message: t("this_field_is_required"),
                                  },
                                })}
                                onChange={async (e) => {
                                  const inputValue = e.target.value; // Get the current value of the input field
                                  handleCountry(e.target.value, item.options);
                                  handleCustomField(item.id, inputValue);
                                  await trigger(item.code);
                                }}
                              >
                                <option>{t("select_the_country")}</option>
                                {item?.options?.map((option, optionIndex) => (
                                  <option key={optionIndex} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            )}
                            {item.type === "text" && item.code === "state" && (
                              <select
                                id={item.code}
                                name={item.name}
                                className={`form-control ${errors[item.name] ? "error-field" : ""
                                  }`}
                                {...register(item.code, {
                                  required: {
                                    value: item.required,
                                    message: t("this_field_is_required"),
                                  },
                                })}
                                onChange={async (e) => {
                                  const inputValue = e.target.value; // Get the current value of the input field
                                  handleCustomField(item.id, inputValue);
                                  await trigger(item.code);
                                }}
                              >
                                {states?.map((option, optionIndex) => (
                                  <option key={optionIndex} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            )}
                            {errors[item.code] && !errors.date_of_birth && (
                              <span className="error-message-validator">
                                {errors[item.code].message}
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                      <div className="col-md-12">
                        <button
                          type="button"
                          className="btn btn-dark text-white float-start back mt-4 rounded-3 bg-color-back"
                          onClick={handleBack}
                        >
                          {t("back")}
                        </button>
                        <button
                          type="button"
                          className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                          onClick={handleNext}
                        >
                          {t("next")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeStep === 3 && (
                <div className="row row_top justify-content-center form-business">
                  <div className="regsiter_step_1_view_left_sec_head">
                    {data?.registrationSteps &&
                      t(`${data?.registrationSteps[activeStep - 1]?.label}`)}
                  </div>
                  <div className="regsiter_second_step_bx">
                    <div className="row">
                      {data?.loginInformation.map((item, index) => (
                        <div className="col-md-12" key={index}>
                          <div className="regsiter_form_box">
                            <label htmlFor={item.code}>
                              {t(item.code)} <span className="text-danger">٭</span>
                            </label>
                            {item.type === "text" && (
                              <>
                                <input
                                  id={item.code}
                                  name={item.code}
                                  type={item.type}
                                  disabled={true}
                                  className={`form-control ${(!checkUsernameField.data?.data?.status &&
                                    checkUsernameField.data?.data?.field ===
                                    "username" &&
                                    checkUsernameField.data?.data?.code === 1117) ||
                                    errors[item.code]
                                    ? "error-field"
                                    : ""
                                    }`}
                                  placeholder={item.placeholder}
                                  {...register(
                                    item.code,
                                    loginUsernameValidator(item, t)
                                  )}
                                  onChangeCapture={async (e) => {
                                    await handleUsernameCheck(e.target);
                                  }}
                                />
                                {!checkUsernameField.data?.data?.status &&
                                  checkUsernameField.data?.data?.field ===
                                  "username" &&
                                  checkUsernameField.data?.data?.code === 1117 && (
                                    <span className="error-message-validator">
                                      {t("username_exists")}
                                    </span>
                                  )}
                              </>
                            )}
                            {item.type === "password" && (
                              <>
                                <input
                                  id={item.code}
                                  name={item.code}
                                  type={showPswrd?'text':item.type}
                                  style={{ marginBottom: "8px" }}
                                  className={`form-control ${errors[item.code] ? "error-field" : ""
                                    }`}
                                  placeholder={item.placeholder}
                                  {...register(
                                    item.code,
                                    loginPasswordValidator(item, t)
                                  )}
                                  onBlur={async () => await trigger(item.code)}
                                />
                                <InputAdornment
                                  position="end"
                                  style={{ position: "absolute", right: 0, top: 124,background:'none'}}
                                >
                                  <IconButton
                                    onClick={handleShowPassword}
                                    onMouseDown={(e) => e.preventDefault()}
                                    style={{background:'none'}}
                                  >

                                    {showPswrd ? <Visibility style={{color:'grey',background:'none'}}/> : <VisibilityOff style={{color:'grey',background:'none'}} />}


                                  </IconButton>
                                </InputAdornment>
                                <PasswordChecklist
                                  rules={passwordRules(item.validation)}
                                  minLength={item.validation.minLength}
                                  value={watch("password", "")}
                                />
                              </>
                            )}
                            {errors[item.code] && (
                              <span className="error-message-validator">
                                {errors[item.code].message}
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                      <div className="col-md-12">
                        <div className="regsiter_form_box">
                          <label className="d-flex" htmlFor="box">
                            <input
                              name={"termsAndCondition"}
                              type="checkbox"
                              id="box"
                              style={{ marginRight: "5px" }}
                              {...register("termsAndCondition", {
                                required: true,
                              })}
                            />
                            <a
                              data-bs-toggle="modal"
                              className="pop_terms_btn"
                              onClick={handleToggleRegisterModal}
                            >
                              {t("iAcceptTermsAndConditions")}
                              <span className="text-danger"> ٭ </span>
                            </a>
                          </label>
                          {errors["termsAndCondition"] &&
                            formValues["termsAndCondition"] === false && (
                              <span className="error-message-validator">
                                *{t("required")}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button
                          type="button"
                          className="btn btn-dark text-white float-start back mt-4 rounded-3 bg-color-back"
                          onClick={handleBack}
                        >
                          {t("back")}
                        </button>
                        <button
                          type="button"
                          className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                          onClick={handleNext}
                        >
                          {t("back")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeStep === 4 && (
                <div className="row row_top justify-content-center form-business">
                  <div className="regsiter_second_step_bx">
                    <div className="regsiter_overview_box">
                      <div className="regsiter_step_1_view_left_sec_head">
                        <strong>{t("product_and_sponsor")}</strong>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mb-3 regsiter_overview_col">
                          <label htmlFor="product">{t("product")}</label>
                          <ol>
                            {
                              formValues.product.map((items) => {
                                return (
                                  <li>
                                    {/* <strong id="product"> */}

                                    {items.name}
                                    {/* </strong> */}
                                  </li>

                                )
                              })
                            }

                          </ol>
                        </div>
                        <div className="col-md-4 mb-3 regsiter_overview_col">
                          <label htmlFor="sponsor">{t("sponsor")}</label>
                          <strong id="sponsor">{data?.sponsorData?.username}</strong>
                        </div>
                        <div className="col-md-4 mb-3 regsiter_overview_col">
                          <label htmlFor="pv">{t("pv")}</label>
                          <strong id="pv">{formValues?.pv}</strong>
                        </div>
                        <div className="col-md-4 mb-3 regsiter_overview_col">
                          <label htmlFor="pv">{t("placement")}</label>
                          <strong id="pv">{formValues?.position == "L" ? "Left" : "Right"}</strong>
                        </div>
                        {/* <div className="col-md-4 mb-3 regsiter_overview_col">
            <label htmlFor="price">{t("price")}</label>
            <strong id="price">{`${
              userSelectedCurrency?.symbolLeft
            } ${CurrencyConverter(
              formValues?.product?.price,
              conversionFactor
            )}`}</strong>
          </div> */}
                        <div className="col-md-4 mb-3 regsiter_overview_col">
                          <label htmlFor="total">{t("totalAmount")}</label>
                          <strong id="total">{`${userSelectedCurrency?.symbolLeft
                            } ${CurrencyConverter(
                              formValues?.totalAmount,
                              conversionFactor
                            )}`}</strong>
                        </div>
                      </div>

                      <div className="regsiter_step_1_view_left_sec_head">
                        <strong>{t(`${data?.registrationSteps[1]?.label}`)}</strong>
                      </div>

                      <div className="row">
                        {Object.entries(formValues)?.map(
                          ([key, value]) =>
                            ![
                              "product",
                              "pv",
                              "regAmount",
                              "termsAndCondition",
                              "totalAmt",
                              "paymentType",
                              "customFields",
                              "bankReceipt",
                              "referralId",
                              "totalAmount",
                              "username",
                              "password",
                              "country",
                              "state",
                              "pv",
                              "amountCode",
                              "epinCount",
                              "position",
                              "expiryDate",
                              "transactionPassword",
                            ].includes(key) && (
                              <div
                                className="col-md-4 mb-3 regsiter_overview_col"
                                key={key}
                              >
                                <label htmlFor={`input-${key}`}>{t(key)}</label>
                                <strong id={`input-${key}`}>{value}</strong>
                              </div>
                            )
                        )}
                        {formValues?.customField?.map(([key, value]) => (
                          <div className="col-md-4 mb-3 regsiter_overview_col">
                            <label htmlFor={`input-${key}`}>{t(key)}</label>
                            <strong id={`input-${key}`}>{value}</strong>
                          </div>
                        ))}
                      </div>

                      <div className="regsiter_step_1_view_left_sec_head">
                        <strong>{t(`${data?.registrationSteps[2]?.label}`)}</strong>
                      </div>

                      <div className="row">
                        <div className="col-md-4 mb-3 regsiter_overview_col">
                          <label htmlFor="username">{t("username")}</label>
                          <strong id="username">{formValues.username}</strong>
                        </div>
                        <div className="col-md-4 mb-3 regsiter_overview_col">
                          <label htmlFor="password">{t("password")}</label>
                          <strong id="password">********</strong>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <button
                          type="button"
                          className="btn btn-dark text-white float-start back mt-4 rounded-3 bg-color-back"
                          onClick={handleBack}
                        >
                          {t("back")}
                        </button>
                        <button
                          type="button"
                          className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                          onClick={handleNext}
                        >
                          {t("next")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeStep === 5 && (
                <div className="row row_top justify-content-center form-business">
                  <div className="col-lg-12 col-md-12" id="payment">
                    <div className="payment_section_tab">
                      <div className="regsiter_step_1_view_left_sec_head">
                        {data?.registrationSteps &&
                          t(`${data?.registrationSteps[activeStep - 1]?.label}`)}
                        <br />
                        <strong>
                          {t("totalAmount")}:{" "}
                          {`${userSelectedCurrency?.symbolLeft} ${CurrencyConverter(
                            formValues?.totalAmount ?? data?.regFee,
                            conversionFactor
                          )}`}
                        </strong>
                      </div>

                      <div className="row">
                        <div className="col-md-3 pe-0">
                          <div
                            className="nav nav-tabs payment_tab_reg"
                            id="nav-tab"
                            role="tablist"
                          >
                            {data?.paymentGateways?.map((tab) => (
                              <Fragment key={tab.id}>
                                <button
                                  key={tab.id}
                                  className={`nav-link ${activeTab === tab.id ? "active" : ""
                                    }`}
                                  id={`nav-${tab.id}-tab`}
                                  data-bs-toggle="tab"
                                  data-bs-target={`#nav-${tab.id}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={`nav-${tab.id}`}
                                  aria-selected={formValues.paymentType === tab.id}
                                  {...register("paymentType", { required: true })}
                                  onClick={() =>
                                    handlePaymentTabClick(tab.id, tab.title)
                                  }
                                >
                                  <i className={tab?.icon}></i> {tab.title}
                                </button>
                              </Fragment>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-9 ps-0">
                          <div
                            className="tab-content p-3 border mb-5 payment__tab_cnt"
                            id="nav-tabContent"
                          >
                            {(formValues.paymentType === undefined ||
                              formValues.paymentType === "") && (
                                <p>
                                  <strong>{t("preferredPaymentPlaceholder")}</strong>
                                </p>
                              )}

                            {data?.paymentGateways?.map((tab) => (
                              <div
                                key={tab.id}
                                className={`tab-pane fade ${activeTab === tab.id ? "show active" : ""
                                  }`}
                                id={`nav-${tab.id}`}
                                role="tabpanel"
                                aria-labelledby={`nav-${tab.id}-tab`}
                              >
                                {tab.title === "free-joining" && (
                                  <p>
                                    <strong>{t("freeJoinPlaceHolder")}</strong>
                                  </p>
                                )}
                                {tab.title === "bank-transfer" && (
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group mb-2">
                                        <label
                                          htmlFor="fileUpload"
                                          style={{ marginBottom: "10px" }}
                                        >
                                          {t("file_upload")}
                                        </label>
                                        <input
                                          type="file"
                                          placeholder=""
                                          className="form-control"
                                          style={{ height: "33px" }}
                                          name="fileUpload"
                                          onChange={handleFileChange}
                                        />
                                        {fileResponse?.success && (
                                          <div style={{ color: "green" }}>
                                            {t(fileResponse?.success)}
                                          </div>
                                        )}
                                        {fileResponse?.error && (
                                          <div style={{ color: "red" }}>
                                            {t(fileResponse?.error)}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <button
                                        href="#"
                                        className="btn btn-sm btn-primary mt-3"
                                        onClick={handleUpload}
                                        disabled={Upload?.isLoading}
                                      >
                                        {Upload?.isLoading
                                          ? "Uploading..."
                                          : t("upload")}
                                      </button>
                                    </div>
                                    {Upload?.data?.status === true && (
                                      <>
                                        <img
                                          src={`${BASE_URL}${Upload?.data?.data?.file?.path}`}
                                          alt="receipt"
                                          style={{
                                            width: "160px",
                                            height: "160px",
                                            padding: "5px",
                                          }}
                                        />
                                        <div style={{ color: "green" }}>
                                          {Upload?.data?.message}
                                        </div>
                                      </>
                                    )}
                                    {Upload?.data?.status === false && (
                                      <span className="error-message-validator">
                                        {Upload?.data?.message}
                                      </span>
                                    )}
                                  </div>
                                )}
                                {/* {tab.title === "stripe" && (
                    <p>
                      <strong>Addon Coming Soon</strong>
                    </p>
                  )} */}
                                {tab.title === "stripe" && (
                                  <div>
                                    <p>
                                      <>
                                        {clientSecret.status && clientSecret.data && (
                                          <Stripe
                                            clientSecret={clientSecret.data}
                                            totalAmount={formValues.totalAmount}
                                            action={"register"}
                                            handleSubmitFinish={handleSubmit}
                                          />
                                        )}
                                      </>
                                    </p>
                                  </div>
                                )}
                                {tab.title === "paypal" && (
                                  <p>
                                    <strong>Addon Coming Soon</strong>
                                  </p>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {errors.paymentType && (
                        <span className="error-message-validator">*Required</span>
                      )}
                    </div>
                    <button
                      type="button"
                      className="btn btn-dark text-white float-start back rounded-3 bg-color-back"
                      onClick={handleBack}
                    >
                      {t("back")}
                    </button>
                    {activeTab != 5 && (
                      <button
                        type="submit"
                        className="btn text-white float-end submit-button rounded-3 bg-color-info"
                        disabled={submitButtonActive}
                        onClick={handleSubmit}
                      >
                        {t("finish")}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
      }


    </div>
  );
};

export default ReplicaRegisterForm;
