import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Rank_report from '../../components/Report/Rank_report';
import { ApiHook } from '../../hooks/apiHook';
import { useState } from 'react';
import TopbarSection from '../../components/Report/TopbarSection';
import CurrentRank from '../../components/Report/CurrentRank';
import { Spinner } from 'react-bootstrap';
import loader from '../../assests/images/spinner2.gif'
function RankReport() {
  const { t } = useTranslation();
  const [rank, setrank] = useState("RETAIL_PURCHASE_INFO")
  const [searchParams, setsearchParams] = useState(0)
  const getUserRankinfo = ApiHook.CallRankCommissionReport(rank, searchParams)
  const getRanks = ApiHook.CallgetRanks()
  const [selectBoxvalue, setselectBoxvalue] = useState()
  const handleSelecthanler = (value) => {
    console.log("==selctvaluye=====", value)
    setselectBoxvalue(value)
    setsearchParams(value?.value)
  }
  useEffect(() => {
    setselectBoxvalue(getRanks?.data?.currentRank)
  }, [getRanks.data])

  console.log("======rankdetails================",getUserRankinfo?.data)
  return (
    <div>
      <div className="page_head_top">{t("rank-report")}</div>
      <div className='row'>
        <div className='col-lg-8'>
          {
            getRanks?.data && (
              <TopbarSection
                t={t}
                rankList={getRanks?.data}
                handleSelecthanler={handleSelecthanler}
                selectBoxvalue={selectBoxvalue}
              />
            )
          }

          <div className="ranking-box">
            {
              getUserRankinfo.isFetching ?
              <center> <img src={loader} className='w-50'/><br/>
             
              </center>
               
                :
                <div>
                  {
                    getUserRankinfo.isFetched && (
                      <Rank_report
                        t={t}
                        rankData={getUserRankinfo?.data}
                        setrank={setrank}
                        selectedRank={rank}
                        loader={getUserRankinfo.isFetching}
                       
                      />
                    )
                  }


                </div>
            }


          </div>

        </div>
        <div className='col-lg-4'>
          <CurrentRank
            t={t}
            data={getRanks?.data}
          
          />
        </div>
      </div>

    </div>
  )
}

export default RankReport