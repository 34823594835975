import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils/formateDate";
import { ApiHook } from "../../../hooks/apiHook";
import { useNavigate } from "react-router-dom";
import anime from "animejs/lib/anime.es.js";
import Loader  from "react-js-loader";

const TreeNode = (props) => {
  const navigate = useNavigate();
  const data = props?.data;
  const plan = props?.plan;

  const NoProfile = "/images/user-profile.png";
  const [expanded, setExpanded] = useState(true);
  const [hoveredItemId, setHoveredItemId] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [isMoreId, setIsMoreId] = useState({
    fatherId: null,
    position: null,
  });
  const listRef = useRef(null);

  const updatedTree = ApiHook.CallUnilevelMore(isMoreId);

  const HandleExpand = (data) => {
    if (data?.children?.length === 0) {
      props.setSelectedUserId(data?.attributes?.id);
    } else {
      setExpanded(!expanded);
    }
  };

  const handleItemHover = (itemId) => {
    setHoveredItemId(itemId);
  };

  const handleDoubleClick = (data) => {
    props.setDoubleUser(data?.attributes?.id);
  };

  const handleIsMore = (data) => {
    setIsMoreId((prev) => ({
      ...prev,
      fatherId: data?.fatherId,
      position: data?.position,
    }));
    if (updatedTree?.isLoading) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  };

  const handleRegistration = (data) => {
    props.setParamsList({
      placement: data?.attributes?.parent,
      position: data?.attributes?.position,
      isRegFromTree: 1,
    });

    if (props.ecomStatus) {
      props.setLinkRegisterCheck(true);
    } else {
      navigate("/register", {
        state: {
          parent: data?.attributes?.parent,
          position: data?.attributes?.position,
        },
      });
    }
  };

  useEffect(() => {
    if (expanded) {
      anime({
        targets: listRef.current,
        translateY: [`${-20}px`, `${0}px`],
        opacity: [0, 1],
        duration: 500,
        easing: "easeInQuad",
      });
    }
  }, [expanded]);

  const popover = (
    <Popover id="popover">
      <div id="treeview_pop">
        <div className="card">
          <div className="card-img-top">
            <div className="card-img-top_img">
              <img
                src={
                  hoveredItemId?.tooltipData?.profilePic
                    ? hoveredItemId?.tooltipData?.profilePic
                    : NoProfile
                }
                alt="profile_photo"
              />
            </div>
            <h5 className="card-title">
              {hoveredItemId?.tooltipData?.username}
            </h5>
            <p className="card-text">
              {hoveredItemId?.tooltipData?.fullName
                ? hoveredItemId?.tooltipData?.fullName
                : "username"}
            </p>
          </div>
          <div className="card-body">
            <div className="user_detail_tabl">
              <table>
                <tbody>
                  {hoveredItemId?.tooltipData?.tableData &&
                    Object?.entries(hoveredItemId.tooltipData.tableData)?.map(
                      ([key, value]) => (
                        <tr key={key}>
                          <td>{key}</td>
                          <td>
                            {key === "joinDate" ? formatDate(value) : value}
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
            {hoveredItemId?.tooltipData?.rankDetails &&
              hoveredItemId?.tooltipData?.rankDetails?.name && (
                <a
                  className="btn btn-rank"
                  style={{
                    backgroundColor:
                      hoveredItemId?.tooltipData?.rankDetails?.color,
                  }}
                >
                  {hoveredItemId?.tooltipData?.rankDetails?.name}
                </a>
              )}
          </div>
        </div>
      </div>
    </Popover>
  );

  return (
    <li>
      {data?.isPlaceholder ? (
        <>
          <a>
            <div className="member-view-box">
            <div className="member-image">
                {data?.isMore && plan === "Unilevel" ? (
                  <>
                    {showLoader ? (
                      <>
                        <Loader
                          type="spinner-default"
                          bgColor={"#954cea"}
                          color={"#954cea"}
                          size={25}
                        />
                      </>
                    ) : (
                      <>
                        <div
                          className="right_more_user_expand_btn"
                          onClick={() => handleIsMore(data?.attributes)}
                        >
                          <i className="fas fa-angle-double-right"></i>
                        </div>
                        <div className="member-details-dwnl-bx">
                          {data?.attributes?.moreChildren} more
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <span
                    className="pulse-button"
                    onClick={() => handleRegistration(data)}
                  >
                    +
                  </span>
                )}
              </div>
            </div>
          </a>
        </>
      ) : (
        <>
          <a
            onClick={() => HandleExpand(data)}
            onMouseEnter={() => handleItemHover(data)}
            onMouseLeave={() => handleItemHover([])}
            onDoubleClick={() => handleDoubleClick(data)}
          >
            <div className="member-view-box">
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="right"
                overlay={popover}
              >
                <div className="member-image">
                  <img
                    src={
                      data?.attributes?.treeIcon === null ||
                      data?.attributes?.treeIcon === ""
                        ? NoProfile
                        : data?.attributes?.treeIcon
                    }
                    alt="Member"
                  />
                  <div className="member-details">
                    <h3>{data?.username}</h3>
                    <div className="member-details-downline-dtl">
                      {plan === "Binary" ? (
                        <>
                          <div className="member-details-dwnl-bx">
                            Left:{" "}
                            <strong>
                              {data?.attributes?.leftChildrenCount}
                            </strong>
                          </div>
                          <div className="member-details-dwnl-bx">
                            Right:{" "}
                            <strong>
                              {data?.attributes?.rightChildrenCount}
                            </strong>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="member-details-dwnl-bx">
                            Children:{" "}
                            <strong>{data?.attributes?.childrenCount}</strong>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </OverlayTrigger>
            </div>
          </a>
          {data?.children?.length > 0 && expanded && (
            <ul ref={listRef}>
              {data?.children?.map((child, index) => {
                return (
                  <TreeNode
                    key={index}
                    data={child}
                    plan={plan}
                    ecomStatus={props.ecomStatus}
                    selectedUserId={props.selectedUserId}
                    setSelectedUserId={props.setSelectedUserId}
                    doubleUser={props.doubleUser}
                    setDoubleUser={props.setDoubleUser}
                    linkRegisterCheck={props.linkRegisterCheck}
                    setLinkRegisterCheck={props.setLinkRegisterCheck}
                    paramsList={props.paramsList}
                    setParamsList={props.setParamsList}
                  />
                );
              })}
            </ul>
          )}
        </>
      )}
    </li>
  );
};

const TreeNodeComponent = (props) => {
  const moduleStatus = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus
  );
  const [linkRegisterCheck, setLinkRegisterCheck] = useState(false);
  const [paramsList, setParamsList] = useState({
    placement: "",
    position: "",
    isRegFromTree: "",
  });
  const genealogyList = useSelector((state) => state.tree?.genealogyList);
  const RegisterLink = ApiHook.CallRegisterLink(
    linkRegisterCheck,
    setLinkRegisterCheck,
    paramsList.placement,
    paramsList.position,
    paramsList.isRegFromTree
  );
  if (RegisterLink.isFetched) {
    window.location.href = RegisterLink.data?.link;
  }
  return (
    <TreeNode
      data={genealogyList}
      plan={moduleStatus?.mlm_plan}
      ecomStatus={moduleStatus?.ecom_register}
      selectedUserId={props.selectedUserId}
      setSelectedUserId={props.setSelectedUserId}
      doubleUser={props.doubleUser}
      setDoubleUser={props.setDoubleUser}
      linkRegisterCheck={linkRegisterCheck}
      setLinkRegisterCheck={setLinkRegisterCheck}
      paramsList={paramsList}
      setParamsList={setParamsList}
    />
  );
};

export default TreeNodeComponent;
