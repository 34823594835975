import { EwalletService } from "../../services/ewallet/ewallet";
import { reportServices } from "../../services/report/report";

export const Tiles = async () => {
  try {
    const response = await EwalletService.callTiles();
    return response;
  } catch (error) {
    return error.message;
  }
};

export const Statement = async (page, itemsPerPage, startDate,
  endDate) => {
  try {
    const response = await EwalletService.callStatement(page, itemsPerPage, startDate,
      endDate);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const TransferHistory = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate
) => {
  try {
    const response = await EwalletService.callTransferHistory(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const PurchaseHistory = async (page, itemsPerPage) => {
  try {
    const response = await EwalletService.callPurchaseHistory(
      page,
      itemsPerPage
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const MyEarnings = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate
) => {
  try {
    const response = await EwalletService.callMyEarnings(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const FundTransfer = async (data) => {
  try {
    const response = await EwalletService.callFundTransfer(data);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const EwalletBalance = async () => {
  try {
    const response = await EwalletService.callEwalletBalance();
    return response;
  } catch (error) {
    console.log(error.message);
  }
};

export const WeeklyReport = async (data) => {
  try {
    const response = await EwalletService.weeklyReport(data);
    return response;
  } catch (error) {
    return error.message;
  }
};
export const PendingCommissionReport = async(data)=>{
  try {
    const response = await EwalletService.PendingCommissionReport(data);
    return response;
  } catch (error) {
    return error.message;
  }
  
}
export const userRankreport=async(rank,searchParams)=>{
try {
  const response=await reportServices.Callrankreport(rank,searchParams)
  return response;
} catch (error) {
  return error
}

}
export const getRanks=async()=>{
  try {
    const response=await reportServices.CallgetRanks()
    return response;
  } catch (error) {
    return error
  }
}
export const getWeeklyreport=async(date)=>{
  try {
    const response=await reportServices.CallweeklyReport(date)
    return response
  } catch (error) {
    return error
  }
}
