import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";


const StripecheckoutForm = ({
  totalAmount,
  action,
  handleClose,
  setclientSecret,
  handleSubmitFinish,
}) => {
  console.log("===action====", action);
  const { t } = useTranslation();
  const Currency = useSelector((state) => state.user?.selectedCurrency);
  const epinPurchase = ApiHook.CallEpinPurchaseSuccess();
  const epinRecharge = ApiHook.CallEpinRechargeSuccess();
  const stripe = useStripe();
  const elements = useElements();
  const paymentElementOptions = {
    layout: "tabs",
  };
  const queryClient = useQueryClient();
  const [error, seterror] = useState("");
  const [loader, setLoader] = useState(false);
  const [btnDisabled, setbtnDisabled] = useState(true);
  const handleSubmit = async (event) => {
    setLoader(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      setLoader(false);

      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        // return_url: "https://example.com/order/123/complete",
        return_url: "http://localhost:3000/payment-success",
      },

      redirect: "if_required",
    });

    if (result.error) {
      setLoader(false);

      seterror(result.error.message);
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      console.log("result.paymentIntent ====== ", result.paymentIntent);
      if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
        if (action === "Epin_purchase") {
          setLoader(false);

          const response = await epinPurchase.mutateAsync({
            payment_id: result?.paymentIntent?.id,
          });
          if (response.status) {
            toast.success("Epin purchase successfully...");
            queryClient.invalidateQueries({ queryKey: ["epin-tiles"] });
            queryClient.invalidateQueries({ queryKey: ["epin-lists"] });
            queryClient.invalidateQueries({queryKey:["recharge-epins"]})
            queryClient.invalidateQueries({
              queryKey: ["purchased-epin-list"],
            });
            setclientSecret("");
            handleClose(false);
          } else {
            setclientSecret("");
            toast.error("Error occured...Please try again..");
            handleClose(false);
          }
        } else if (action === "Epin_recharge") {
          setLoader(false);
          const response = await epinRecharge.mutateAsync({
            payment_id: result?.paymentIntent?.id,
          });
          if (response.status) {
            queryClient.invalidateQueries({ queryKey: ["epin-tiles"] });
            queryClient.invalidateQueries({ queryKey: ["epin-lists"] });
            queryClient.invalidateQueries({
              queryKey: ["purchased-epin-list"],
            });
            queryClient.invalidateQueries({queryKey:["recharge-epins"]})
            setclientSecret("");
            handleClose(false);
            toast.success("Epin recharge  successfully...");
          } else {
            setclientSecret("");
            toast.error("Error occured...Please try again..");
            handleClose(false);
          }
        } else if (action === "register") {
          const response = await handleSubmitFinish(result.paymentIntent.id);
          console.log("response inside stripe ===>>>>", response);
        }
      } else {
        setLoader(false);
      }
    }
  };
  const elementRendered = () => {
    setbtnDisabled(false);
  };
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  return (
    <form onSubmit={handleSubmit}>
      {error && (
        <Alert severity="error" className="mb-1">
          {error}
        </Alert>
      )}

      <h5>
        Total Amount :{" "}
        <span>
          {`${Currency.symbolLeft} ${CurrencyConverter(totalAmount,conversionFactor)}`}
        </span>
      </h5>

      <PaymentElement
        options={paymentElementOptions}
        onReady={elementRendered}
      />
      <button
        disabled={btnDisabled || loader}
        className="btn btn-primary submit_btn mt-3"
      >
        {loader ? (
          <div>
            <i className="fa-solid fa-spinner fa-spin" /> {t("processing")}
          </div>
        ) : action === "register" ? (
          t("finish")
        ) : (
         t("submit")
        )}
      </button>
    </form>
  );
};

export default StripecheckoutForm;
