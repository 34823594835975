import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'
import { ApiHook } from '../../hooks/apiHook';
import { useState } from 'react';
import moment from 'moment';
import { formatDateWithoutTime } from "../../utils/formateDate";
import { TableSkeleton } from '../../components/Common/table/TableSkeleton';
function WeeklyReport() {
    const { t } = useTranslation();
    const [date, setdate] = useState(moment().format('YYYY-MM-DD'))
    const dateHandler = (e) => {
        setdate(e.target.value)
    }
    const getWeeklydata = ApiHook.Callweeklyreport(date)
   
const header=["startDate","endDate","left_carry","right_carry","Left_Leg_Retail_Pv","Right_Leg_Retail_Pv","Left_Leg_Sponsor_Count","Right_Leg_Sponsor_Count","Left_Leg_Users_Count","Right_Leg_Users_Count"]
    return (
        <div>
            <div className="page_head_top">{t("weekly-report")}</div>
            <div className="ewallet_table_section">
                <div className="ewallet_table_section_cnt"></div>{" "}
                <div className="filter_Section">
                    <div className="row justify-content-between">
                        <div className="col-md-4">
                            <div className="right_search_div d-flex gap-1">

                                <Form.Control
                                    type="date"
                                    name="duedate"
                                    placeholder="Due date"
                                    value={date}
                                    onChange={dateHandler}
                                />

                                {/* <SubmitButton
                                    isSubmitting=""
                                    text="Reset"
                                    className="btn btn-secondary"
                                    click={resetHandler}
                                    id="resetButton"
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive min-hieght-table">
                    <div className="table-container">
                       
                        <table className="striped" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    {header?.map((item, index) => (
                                        <th key={index}>{t(item)}</th>
                                    ))}
                                </tr>
                            </thead>
                            {
                                getWeeklydata?.isFetching?
                                <tbody>
                                    <TableSkeleton rowCount={5} cellCount={10} />
                                </tbody>
                                :
                                <>
                                  {getWeeklydata?.data?.length <= 0 ? (
                                <tbody>
                                    <tr>
                                        <td colSpan="6">
                                            <div className="nodata-table-view">
                                                <div className="nodata-table-view-box">
                                                    <div className="nodata-table-view-box-img">
                                                        <img src="/images/no-data-image1.jpg" alt="" />
                                                    </div>
                                                    <div className="nodata-table-view-box-txt">
                                                        {t("sorry_no_data_found")}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    {
                                        getWeeklydata?.data &&
                                        getWeeklydata?.data?.map((row, index) => (
                                            console.log("====tabledataaa===",row),
                                            <tr key={index}>
                                                <td> {formatDateWithoutTime(row.startDate)}</td>
                                                <td>{formatDateWithoutTime(row.endDate)}</td>
                                                <td>{row?.left_carry ?? 0}</td>
                                                <td>{row?.right_carry ?? 0}</td>
                                                <td>{row?.Left_Leg_Retail_Pv ?? 0}</td>
                                                <td>{row?.Right_Leg_Retail_Pv ?? 0}</td>
                                                <td>{row?.Left_Leg_Sponsor_Count ?? 0}</td>
                                                <td>{row?.Right_Leg_Sponsor_Count ?? 0}</td>
                                                <td>{row?.Left_Leg_Users_Count ?? 0}</td>
                                                <td>{row?.Right_Leg_Users_Count ?? 0}</td>
                                            </tr>
                                        ))
                                    }
                                    
                                </tbody>
                            )}
                                </>
                               
                            }
                          
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WeeklyReport