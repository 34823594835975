import React from 'react'
import { useTranslation } from 'react-i18next'
import ListGroup from 'react-bootstrap/ListGroup';
import { ApiHook } from '../../hooks/apiHook';
import { useState } from 'react';
import { Form } from 'react-bootstrap'
import { toast } from "react-toastify";
function GenerateReplicalink({ getReplicaLink }) {
    const [fatherName, setfatherName] = useState('')
    const [listDisableStatus, setDisableStatus] = useState(false)
    const getAvailiableplacement = ApiHook.CallgetAvailiblePlacement(fatherName)
    const { t } = useTranslation();
    const [selectedData, setselectedData] = useState({
        id: 0,
        username: '',
        position: ''
    })
    const [disableBtn, setdisableBtn] = useState(true)
    const [btnShow, setbtnShow] = useState(false)
    const [replicaLink, setreplicaLink] = useState("")
    const generateReplicalinkApi = ApiHook.CallgenerateReplicalink()
    const changeHandler = (e) => {
        const { name, value } = e.target
        setfatherName(value)
        setselectedData({
            ...selectedData,
            id: 0,
            username: '',
            position: ''
        })
    }
    const [error, seErr] = useState('')
    console.log("======data===========dsfdsf", getAvailiableplacement?.data)
    const radioHandler = (e, selectedValue) => {
        setbtnShow(true)
        setselectedData((prev) => ({
            ...prev,
            id: selectedValue.id,
            username: selectedValue.username,
            position: ''
        }))
    }
    const selectPositionHandler = (e, id) => {
        const { name, value } = e.target
        if (id === selectedData.id) {
            setselectedData({
                ...selectedData,
                [name]: value
            })
            seErr('')
        } else {
            seErr('Please choose a valid position')
        }

    }

    const generateReplicalink = async () => {
        try {
            let data = {
                fatherId: selectedData.id,
                fatherName: selectedData.username,
                position: selectedData.position
            }
            const response = await generateReplicalinkApi.mutateAsync(data)
            if (response.status) {
                setbtnShow(false)
                getReplicaLink(response?.data)
                setreplicaLink(response?.data[0]?.link)
                toast.success("Link created successfully....");
            }

        } catch (error) {

        }
    }

    return (
        <div className="modal_change_password_right_cnt">
            <div className="mb-3 row tabBlockClass">
                <span>{error}</span>
                <div className="form-group mb-2">
                    <div>
                        <input
                            name="branchName"
                            type="text"
                            className="form-control"
                            id="branchName"
                            value={fatherName}
                            placeholder='Enter father username'
                            onChange={changeHandler}
                        />
                        {
                            getAvailiableplacement?.data?.length > 0 ?
                                <>
                                    <ListGroup className='fathers-list'>
                                        {
                                            getAvailiableplacement?.data &&
                                            getAvailiableplacement?.data?.map((value, index) => {

                                                return (
                                                    <ListGroup.Item key={index} disabled={generateReplicalinkApi.isLoading}>

                                                        <Form>
                                                            <Form.Check
                                                                inline
                                                                name={`placement-${index}`}
                                                                type='radio'
                                                                checked={value.id === selectedData.id ? true : false}
                                                                onChange={(e) => radioHandler(e, value)}

                                                            />
                                                            {value.username}
                                                            <select className='position-chooser'
                                                                name='position'
                                                                onChange={(e) => selectPositionHandler(e, value.id)}
                                                                disabled={selectedData.id != value.id ? true : false}
                                                            >
                                                                <option value="">Position</option>
                                                                {

                                                                    value?.position_options.map((item) => {
                                                                        return (
                                                                            <option value={item.value}>{item.label}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </select>

                                                        </Form>



                                                    </ListGroup.Item>
                                                )
                                            })
                                        }


                                    </ListGroup>
                                    <button class="btn btn-primary submit_btn mt-3"
                                        type="submit"
                                        disabled={!selectedData.position || generateReplicalinkApi?.isLoading}
                                        hidden={!btnShow}
                                        onClick={generateReplicalink}>
                                        {
                                            generateReplicalinkApi?.isLoading ?
                                                <span> <i class="fa-solid fa-slash fa-spin fa-spin-reverse"></i> {t('processing')}</span>
                                                : t('createLink')
                                        }

                                    </button>
                                </>

                                :
                                <div>
                                    <div className="no-data-div">
                                        <div className="no-data-div-image">
                                            <img src="/images/nodata-image.png" alt="" />
                                        </div>
                                        <p>{t("noFatherFound")}</p>
                                    </div>
                                </div>

                        }

                    </div>
                </div>

            </div>
        </div>

    )
}

export default GenerateReplicalink